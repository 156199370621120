import Country from "@/services/Country";
const state = {
    countryItems: []



}

const mutations = {
    SET_COUNTRY (state, payload) {
        state.countryItems = payload.data;
    },

}

const actions = {
    loadCountries ({ commit }) {
        return new Promise((resolve, reject) => {
            Country.loadCountries().then(( response) => {
                commit('SET_COUNTRY', response.data)
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    }
}

const getters = {
    countryItems: state => state.countryItems,
    countryNameById: state => (id) => {
        let found = state.countryItems.filter(country => country.key === id);
        if(found.length === 0) {
            return '';
        }
        return found[0].label;
    },
    countryIdByName: state => (label) => {
        return state.countryItems.filter(country => country.label === label);
    }
}


const countryModule = {
    state,
    mutations,
    actions,
    getters
}
export default countryModule;