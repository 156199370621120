import Vue from 'vue'
import App from './App.vue'
import VueGtm from '@gtm-support/vue2-gtm';
import VueI18n from 'vue-i18n';
import VueCookies from 'vue-cookies';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faCartShopping, faGlobe, faEllipsisVertical, faSpinner, faBuilding, faCheck, faEnvelope, faTimes, faHouse, faAddressBook, faMoneyBill, faTrashCan, faPowerOff, faTrash, faPen, faInfoCircle, faInfo, faClock, faCompass, faClipboard, faMap, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '../src/assets/css/a7sitetemplate.scss';
import VueEasyLightbox from 'vue-easy-lightbox'
import VueGtag from "vue-gtag";


//import { StripePlugin } from '@vue-stripe/vue-stripe';

import store from './store'
import router from './router'
import messages from './messages'
import vueNumeralFilterInstaller from "vue-numeral-filter";
import VeeValidate from 'vee-validate';


const getLanguage = () => (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || 'en';
const locale = getLanguage();
const short = locale.split('-',1)[0];
//import backend from "@/services/backend";




// Bootstrap
Vue.use(BootstrapVue);
Vue.use(VueCookies);
// i18n
const i18n = new VueI18n({
  locale: locale,
  numberFormats: {
    'de': {
      currency: {
        style: 'currency', currency: 'EUR'
      }
    },
    'en': {
      currency: {
        style: 'currency', currency: 'EUR'
      }
    },
    'hu': {
      currency: {
        style: 'currency', currency: 'EUR'
      }
    },
    'cz': {
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    },
  },
  messages: messages
});






// Fontawesome
Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add({ faUser, faCartShopping, faGlobe, faEllipsisVertical, faSpinner, faBuilding, faCheck, faEnvelope, faTimes, faHouse, faAddressBook, faMoneyBill, faTrashCan, faPowerOff, faTrash, faPen, faInfoCircle, faInfo, faClock, faCompass, faClipboard, faMap, faDownload });
Vue.config.productionTip = false
Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    valid: "is-valid",
    invalid: "is-invalid"
  }
});

//load initial Cart Info
store.dispatch('checkLogin');
store.dispatch('getCartItems');
store.dispatch('loadCountries');


Vue.use(vueNumeralFilterInstaller, { locale: short });

Vue.use(VueEasyLightbox)

const vueApp = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
});

console.log('main');
console.log(short);
console.log(localStorage.getItem('appLanguage'));

if(localStorage.getItem('appLanguage') === null || localStorage.getItem('appLanguage') !== short) {
  store.commit('setAppLanguage', short);
  localStorage.setItem('appLanguage',short);
  i18n.locale = short;
}

store.dispatch('loadTranslations', localStorage.getItem('appLanguage')).then(() => {
  vueApp.$mount('#app');
});

Vue.use(VueGtm, {
  id: 'GTM-KZHV9HMG', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  // queryParams: {
  //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x',
  // },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});


Vue.use(VueGtag, {
      config: {id: "G-4DTVMHBF3N", nonce: "2726c7f26c"}
  , nonce: "2726c7f26c"
    }
);


// let instance = new Validator({ trueField: 'truthy' });

// // Also there is an instance 'extend' method for convenience.
// instance.extend('falsy', (value) => ! value);
//
//
// instance.attach({
//   name: 'falseField',
//   rules: 'falsy'
// });
