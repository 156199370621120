<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted(){
    let lang = this.$store.getters.getAppLanguage
    this.$i18n.locale = lang
  },
}
</script>

<style lang="scss">
</style>
