<template>
  <div>
    <MainNavigation/>
    <BackgroundImagePanel min-height="50vh">
      <div class="row">
        <div class="col-md-12">
asdasdas
        </div>

      </div>
    </BackgroundImagePanel>
    <Footer/>
  </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation'
import Footer from '@/components/Footer'
import BackgroundImagePanel from "@/components/BackgroundImagePanel";

export default {
  components: {
    MainNavigation, Footer, BackgroundImagePanel
  },
  // logout() {
  //   this.$store.dispatch("setLogout").then(() => {
  //     this.$router.push({ name: 'home' });
  //   }, async error => {
  //     console.error(error);
  //   })
  // }

}
</script>

<style></style>