<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="dekra-bannerbg" style="height: 100%;">
          <h3 class="mt-0">{{ getMessage('thankyoufororder') }}</h3>
          <div class="row">
            <div class="col-md-8">
              <p>{{ getMessage('nextsteps') }}</p>
              <p>{{ getMessage('nextsteps2') }}</p>
              <ul>
                <li>{{ getMessage('successstep1') }}</li>
                <li>{{ getMessage('successstep2') }}</li>
              </ul>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'checkout-success-view',
  computed: {
    ...mapGetters(['getMessage']),
  },
  mounted() {
    let o = {
      orderHash: this.$route.query.oh,
      orderId: this.$route.query.oid
    }
    this.$store.dispatch("getOrderInfo", o ).then(async response => {

      if(response.data !== '') {
        let items = [];
        response.data.transactionProducts.forEach(item => {
          item.item_id = item.sku;
          item.item_name = 'Plaque'
          items.push(item);
        });

        let total = response.data.transactionTotal + response.data.transactionTax;
        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id : response.data.transactionId,
            currency: "EUR",
            value: total,
            tax: response.data.transactionTax,
            shipping: response.data.transactionShipping,
            items: items
          }
        });
      }

    }, async error => {
      this.haserror = true;
      this.$refs.errormsg.innerText = await this.getMessage(error);

      this.loading = false;
    })
    this.$store.state.orderId = null;
    this.$store.state.orderHash = null;

    this.$gtm.trackView('Payment Success', 'currentPath');

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
