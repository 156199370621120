import LoginView from "@/views/LoginView";
import RegistrationView from "@/views/RegistrationView";
import CartView from "@/views/CartView";
import AddressView from "@/components/checkout/AddressView";
import PaymentView from "@/components/checkout/PaymentView";
import ErrorView from "@/components/checkout/ErrorView";
import SuccessView from "@/components/checkout/SuccessView";
import CheckoutView from "@/views/CheckoutView";
import AccountView from "@/views/AccountView";
import SuccessPage from "@/views/SuccessView";
import ImprintView from "@/views/footer/ImprintView";
import DataProtectionView from "@/views/footer/DataProtectionView";
import DataPrivacySettings from "@/views/footer/DataPrivacySettings";
import TermsAndConditions from "@/views/footer/TermsAndConditions";
import Translation from "@/services/Translation";
import HomeView from "@/views/HomeView";
import AccountAddressView from "@/components/account/AccountAddressView";
import AccountProfileView from "@/components/account/AccountProfileView";
import AccountOrdersView from "@/components/account/AccountOrdersView";
import AccountOverviewView from "@/components/account/AccountOverviewView";
import store from "@/store";
import LogoutView from "@/views/LogoutView";
import ConfirmationMailSent from "@/components/user/ConfirmationMailSent";
import RegistrationSuccess from "@/components/user/RegistrationSuccess";
import RegistrationError from "@/components/user/RegistrationError";


const routes = [
    /*
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'Home'
        },
        component: HomeView,
        beforeEnter: Translation.routeMiddleware,
    },

     */
    {
        path: '/:locale/login',
        name: 'login',
        meta: {
            title: 'Login'
        },
        component: LoginView,
    },
    {
        path: '/:locale/logout',
        name: 'Logout',
        meta: {
            title: 'Logout'
        },
        component: LogoutView
    },
    {
        path: '/:locale/register',
        name: 'register',
        meta: {
            title: 'Register'
        },
        component: RegistrationView
    },
    {
        path: '/:locale/register/confirmationmail',
        name: 'register-confirmationmail',
        meta: {
            title: 'Register'
        },
        component: ConfirmationMailSent,
    },
    {
        path: '/:locale/register/success',
        name: 'register-success',
        meta: {
            title: 'Register'
        },
        component: RegistrationSuccess,
    },
    {
        path: '/:locale/register/error',
        name: 'register-error',
        meta: {
            title: 'Register'
        },
        component: RegistrationError,
    },
    {
        path: '/:locale/cart',
        name: 'Cart',
        meta: {
            title: 'Cart'
        },
        beforeEnter(to, from, next) {
            checkUserCart(next)
        },
        component: CartView
    },
    {
        path: '/:locale/checkout',
        name: 'checkout',
        meta: {
            title: 'Checkout'
        },
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'address',
                name: 'Address',
                meta: {
                    title: 'Checkout'
                },
                beforeEnter(to, from, next) {
                    checkUserCart(next)
                },
                component: AddressView,
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'payment',
                meta: {
                    title: 'Checkout'
                },
                beforeEnter(to, from, next) {
                    checkUserCartAndValidate(next)
                },
                component: PaymentView,
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'payment-error',
                meta: {
                    title: 'Error'
                },
                component: ErrorView,
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'payment/success',
                meta: {
                    title: 'Success'
                },
                component: SuccessView,
            },
        ],
        component: CheckoutView
    },
    {
        path: '/:locale/account',
        name: 'account',
        redirect: '/:locale/account/overview',
        meta: {
            title: 'Account'
        },
        beforeEnter(to, from, next) {
            checkUserSession(next)
        },
        children: [
            {
                path: 'overview',
                name: 'account-overview',
                meta: {
                    title: 'Overview'
                },
                component: AccountOverviewView,
                beforeEnter: (to, from, next) => {
                    handleUserPermission('account', next)
                }
            },
            {
                path: 'orders',
                name: 'account-orders',
                meta: {
                    title: 'Orders'
                },
                component: AccountOrdersView,
                beforeEnter: (to, from, next) => {
                    handleUserPermission('orders', next)
                }
            },
            {
                path: 'profile',
                name: 'account-profile',
                meta: {
                    title: 'Profile'
                },
                component: AccountProfileView,
                beforeEnter: (to, from, next) => {
                    handleUserPermission('profile', next)
                }
            },
            {
                path: 'address',
                name: 'account-address',
                meta: {
                    title: 'Address'
                },
                component: AccountAddressView,
                beforeEnter: (to, from, next) => {
                    handleUserPermission('address', next)
                }
            },
        ],
        component: AccountView
    },
    {
        path: '/:locale/success',
        name: 'Success',
        meta: {
            title: 'Success'
        },
        component: SuccessPage
    },
    {
        path: '/:locale/imprint',
        name: 'Imprint',
        meta: {
            title: 'Imprint'
        },
        component: ImprintView
    },
    {
        path: '/:locale/data-protection',
        name: 'Data Protection',
        meta: {
            title: 'Data Protection'
        },
        component: DataProtectionView
    },
    {
        path: '/:locale/privacy-settings',
        name: 'Privacy Settings',
        meta: {
            title: 'Privacy Settings'
        },
        component: DataPrivacySettings
    },
    {
        path: '/:locale/terms-and-conditions',
        name: 'Terms and Conditions',
        meta: {
            title: 'Terms and Conditions'
        },
        component: TermsAndConditions
    },
    {
        path: '/:locale',
        name: 'home',
        meta: {
            title: 'Home'
        },
        component: HomeView,
        beforeEnter: Translation.routeMiddleware,
    },
    {
        path: '*',
        redirect() {
            return Translation.getUserDefaultLanguage();
        }
    }
]

function handleUserPermission(page, next) {
    store.dispatch('loadUserPermission', page).then((result) => {
        if (result) {
            next()
        } else {
            next({name: 'home'})
        }
    }).catch(function () {
        next({name: 'home'})
    });
}

function checkUserSession(next) {
    store.dispatch('checkLogin').then((result) => {
        if (result) {
            next()
        } else {
            next({name: 'login'})
        }
    }).catch(function () {
        store.dispatch('checkLogin').then(() => {
            next({name: 'login'})
        })
    });
}

function checkUserCart(next) {
    store.dispatch('getCartItems').then((result) => {
        if (result) {
            next();
        }
    }).catch(function () {
        next();
    });
}

function checkUserCartAndValidate(next) {
    store.dispatch('getCartItems').then((result) => {
        if (result) {
            if (result.data.data.list.length > 0 || Object.keys(result.data.data.list).length > 0) {
                next();
            } else {
                console.log('ELSE');
                next({name: 'cart'})
            }
        }
    }).catch(function () {
        console.log('CATCH');
        next({name: 'cart'})
    });
}

export default routes