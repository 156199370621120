<template>
  <div>
    <MainNavigation />
    <BackgroundImagePanel :header="getMessage('headline1')" render-html="true">
      <div
          :is="currentShopPage"
          v-on:shop-start-view="shopStartView"
      >
      </div>
    </BackgroundImagePanel>
    <BottomInfoText />
    <Footer />
  </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation'
import Footer from '@/components/Footer'
import BackgroundImagePanel from "@/components/BackgroundImagePanel";
import ShopStartView from "@/components/shop/StartView";
import ShopNewProductView from "@/components/shop/NewProductView";
import BottomInfoText from "@/components/BottomInfoText";
import {mapGetters} from "vuex";



export default {
  components: {
    BottomInfoText,
    MainNavigation, ShopStartView, ShopNewProductView, Footer, BackgroundImagePanel,
  },
  computed: {
    ...mapGetters(['getMessage']),
  },
  data() {
    return {
      currentShopPage: 'shop-start-view',
    }
  },
  methods: {
    shopStartView(){
      this.currentShopPage = 'shop-start-view'
    }
  },
  mounted(){
    if(location.hash === '#cart'){
      this.currentShopPage = 'shop-start-view'
    }
  }
}
</script>

<style></style>