<template>
  <div class="d-flex justify-content-center align-items-center" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgb(255 255 255 / 80%); z-index: 1000;">
    <font-awesome-icon style="font-size: 52px; color: #11843D;" icon="fa-solid fa-spinner" spin/>
  </div>
</template>

<script>
export default {
  name: 'loading-spinner',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
