
import Order from "@/services/Order";
const state = {
    order: {},
    orderInfo: {},
    orderId: null,
    orderHash: null


}

const mutations = {
    SET_ORDER (state, payload) {
        state.order = payload.data;
        state.orderId = payload.data.orderId;
        state.orderHash = payload.data.orderhash;
    },
    SET_ORDER_INFO (state, payload) {
        state.orderInfo = payload.data;
    }

}

const actions = {
    createOrder ({ commit }, o) {
        return new Promise((resolve, reject) => {
            Order.create(o).then(( response) => {
                commit('SET_ORDER', response.data)
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    },
    getOrderInfo ({ commit }, o) {
        return new Promise((resolve, reject) => {
            Order.info(o).then(( response) => {
                commit('SET_ORDER_INFO', response.data)
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    }
}

const getters = {
    order: state => state.order,
    orderId: state => state.orderId,
    orderHash: state => state.orderHash
}


const orderModule = {
    state,
    mutations,
    actions,
    getters
}
export default orderModule;