<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="white">
      <div class="container">
        <div class="navborderbottom">
          <router-link to="/" target="_self" class="navbar-brand">
            <img src="../assets/img/dekra-logo.png">
          </router-link>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <li class="nav-item">
                <router-link v-if="!getValidLogin" target="_self" active-class="active" class="nav-link" :to="{ name: 'login', params: { locale: this.$i18n.locale }}">{{ getMessage('login') }}&nbsp;&nbsp;<font-awesome-icon style="font-size: 24px; color: #11843D;" icon="fa-solid fa-user" /></router-link>
                <router-link v-if="getValidLogin" target="_self" active-class="active" class="nav-link" :to="{ name: 'account', params: { locale: this.$i18n.locale }}">Account&nbsp;&nbsp;<font-awesome-icon style="font-size: 24px; color: #11843D;" icon="fa-solid fa-user" /></router-link>
              </li>
              <li class="nav-item">
                <router-link target="_self" active-class="active" class="nav-link" :to="{ name: 'Cart', params: { locale: this.$i18n.locale }}">{{ getMessage('navcart') }}({{ getCartCount }})<font-awesome-icon style="font-size: 24px; color: #11843D;" icon="fa-solid fa-cart-shopping" /></router-link>
              </li>
              <b-nav-item-dropdown v-model="$i18n.locale">
                <template slot="button-content">
                  {{ getMessage('language') }}&nbsp;&nbsp;<font-awesome-icon style="font-size: 24px; color: #11843D;" icon="fa-solid fa-globe" />
                </template>
                <b-dropdown-item v-for="(lang, i) in getAvailableLanguages" :key="`lang-${i}`" :value="lang.language" href="#" v-on:click="changeLang(lang.language)">{{ lang.name }}</b-dropdown-item>
              </b-nav-item-dropdown>
<!--              <b-nav-item href="#">{{ getMessage('more') }}&nbsp;&nbsp;<font-awesome-icon style="font-size: 24px; color: #11843D;" icon="fa-solid fa-ellipsis-vertical" /></b-nav-item>-->
            </b-navbar-nav>
          </b-collapse>
        </div>
      </div>
    </b-navbar>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'main-navigation',
  computed: {
    ...mapGetters(['getMessage', 'getAvailableLanguages', 'getValidLogin']),
    getCartCount() {
      return this.$store.getters.getCartCount;
    }
  },
  methods: {
    changeLang(locale){
      this.$store.commit('setAppLanguage', locale);
      this.$store.dispatch('loadTranslations', locale);
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: {locale} });
        this.$router.push(to.location);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
