import Product from "@/services/Product";

const state = {
    product: []
}

const mutations = {
    ADD_PRODUCT_ITEM(state, payload) {
        state.product[payload.data.vin] = payload.data;
    },
    UPDATE_PRODUCT_ITEM_FILE(state, payload) {
        state.product[payload.data.vin].file = payload.data.file;
    }
}

const actions = {

    getVINData({commit}, o) {
        return new Promise((resolve, reject) => {
            Product.getNewProduct(o).then(( response) => {
                if(response.status === 200) {
                    commit('ADD_PRODUCT_ITEM', response.data)
                }
                if(response.status === 208) {
                    resolve(response);
                }
                resolve(response);
            }, error => {
                reject(error);
            });
        });

    },
    uploadFile({commit}, o) {
        return new Promise((resolve, reject) => {
            Product.uploadProductFile(o).then((response) => {
                commit('UPDATE_PRODUCT_ITEM_FILE', response.data);
                resolve(response);
            }, error => {
                reject(error);
            })
        });
    }
}

const getters = {
    productItemByVin: (state) => (vin) => {
        return state.product[vin];
    }
}


const productModule = {
    state,
    mutations,
    actions,
    getters
}

export default productModule;