import Vue from 'vue'
import VueI18n from 'vue-i18n'
import lang_de from './de.json'
import lang_en from './en.json'

Vue.use(VueI18n);

const messages = {
    en: lang_en,
    de: lang_de
}

export default messages;