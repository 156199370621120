import Access from "@/services/Access";
import User from "@/services/User";

const state = {
    permission: [],
    permissions: [],
    user_orders: [],
    open_user_orders: [],
    last_user_orders: [],
    user_profile: [],
    user_addresses: [],
    user_default_address: [],
    user: [],
    jwt: '',
    lastCheck: -1,
    validLogin: localStorage.getItem('validLogin') || false
}

const mutations = {
    SET_PERMISSION (state, payload) {
        state.permission = payload.data;
    },
    SET_PERMISSIONS (state, payload) {
        state.permissions = payload.data;
    },
    SET_USER_ORDERS (state, payload) {
        state.user_orders = payload.data;
    },
    SET_OPEN_USER_ORDERS (state, payload) {
        state.open_user_orders = payload.data;
    },
    SET_LAST_USER_ORDERS (state, payload) {
        state.last_user_orders = payload.data;
    },
    SET_USER_PROFILE (state, payload) {
        state.user_profile = payload.data;
    },
    SET_USER_ADDRESSES (state, payload) {
        state.user_addresses = payload.data;
    },
    UPDATE_USER_DEFAULT_ADDRESS(state, payload) {
        state.user_default_address = payload.data;
    },
    SET_REGISTRATION(state, payload) {
        state.user = payload.data;
    },
    SET_LOGIN(state, payload) {
        state.user = payload.user;
        state.jwt = payload.authorisation.token;
        localStorage.setItem('jwt',state.jwt);
        var d = Date.now();
        state.lastCheck = d;
        state.validLogin = true;
        localStorage.setItem('lastCheck',d);
        localStorage.setItem('validLogin', true);
        console.log(state.validLogin);
        console.log(state.user);
        console.log(state.jwt);
    },
    SET_CHECK(state, payload) {
        var d = Date.now();
        state.lastCheck = d;
        state.validLogin = true;
        localStorage.setItem('lastCheck', d);
        localStorage.setItem('validLogin', true);
        state.user = payload.user;
    },
    SET_LOGOUT(state) {
        state.lastCheck = -1;
        state.validLogin = false;
        state.jwt = '';
        state.user = null;
        localStorage.setItem('lastCheck', -1);
        localStorage.setItem('validLogin', false);
    },
    DOWNLOAD_INVOICE (state, payload) {
        state.open_user_orders = payload.data;
    },
    DOWNLOAD_CERTIFICATE (state, payload) {
        state.open_user_orders = payload.data;
    },
}

const actions = {
    loadUserPermission ({ commit }, page) {
        return new Promise((resolve, reject) => {
            Access.loadUserPermission(page).then((response) => {
                commit('SET_PERMISSION', response.data)
                resolve(response);
            }, error => {
                commit('SET_LOGOUT');
                reject(error);
            });
        });
    },
    loadUserPermissions ({ commit }) {
        return new Promise((resolve, reject) => {
            Access.loadUserPermissions().then((response) => {
                commit('SET_PERMISSIONS', response.data)
                resolve(response.data);
            }, error => {
                commit('SET_LOGOUT');
                reject(error);
            });
        });
    },
    loadUserOrders({ commit }) {
        return new Promise((resolve, reject) => {
            User.loadUserOrders().then((response) => {
                commit('SET_USER_ORDERS', response.data)
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    },
    loadOpenUserOrders({ commit }) {
        return new Promise((resolve, reject) => {
            User.loadOpenUserOrders().then((response) => {
                commit('SET_OPEN_USER_ORDERS', response.data)
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    },
    loadLastUserOrders({ commit }) {
        return new Promise((resolve, reject) => {
            User.loadLastUserOrders().then((response) => {
                commit('SET_LAST_USER_ORDERS', response.data)
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    },
    loadUserProfile({ commit }) {
        return new Promise((resolve, reject) => {
            User.loadUserProfile().then((response) => {
                commit('SET_USER_PROFILE', response.data)
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    },
    saveUserProfile({ commit }, o) {
        return new Promise((resolve, reject) => {
            User.saveUserProfile(o).then((response) => {
                commit('SET_USER_PROFILE', response.data)
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    },
    saveUserPassword({ commit }, o) {
        return new Promise((resolve, reject) => {
            User.saveUserPassword(o).then((response) => {
                commit('SET_USER_PROFILE', response.data)
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    },
    loadUserAddresses({ commit }) {
        return new Promise((resolve, reject) => {
            User.loadUserAddresses().then((response) => {
                commit('SET_USER_ADDRESSES', response.data)
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    },
    updateUserDefaultAddress({commit}, o) {
        return new Promise((resolve, reject) => {
            User.updateUserDefaultAddress(o).then((response) => {
                commit('UPDATE_USER_DEFAULT_ADDRESS', response.data);
                resolve(response.data);
            }, error => {
                reject(error);
            })
        });
    },
    setRegistration ({ commit }, user) {
        return new Promise((resolve, reject) => {
            User.setRegistration(user).then(( response) => {
                commit('SET_REGISTRATION', response.data)
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    },
    setLogin ({ commit }, user) {
        return new Promise((resolve, reject) => {
            User.setLogin(user).then(( response) => {
                commit('SET_LOGIN', response.data)
                resolve(response);
            }, error => {
                commit('SET_LOGOUT');
                reject(error);
            });
        });
    },
    setLogout ({ commit }) {
        return new Promise((resolve, reject) => {
            User.setLogout().then(( response) => {
                commit('SET_LOGOUT')
                resolve(response);
            }, error => {
                commit('SET_LOGOUT');
                reject(error);
            });
        });
    },
    checkLogin ({ commit }) {
        return new Promise((resolve, reject) => {
            User.check().then(( response) => {
                if(response.data.status === true) {
                    commit('SET_CHECK', response.data);
                } else {
                    commit('SET_LOGOUT');
                }
                resolve(response);
            }, error => {
                commit('SET_LOGOUT');
                reject(error);
            });
        });
    },
    downloadInvoice({ commit }, o) {
        return new Promise((resolve, reject) => {
            User.downloadInvoice(o).then((response) => {
                commit('DOWNLOAD_INVOICE', response.data)
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    },
    downloadCertificate({ commit }, o) {
        return new Promise((resolve, reject) => {
            User.downloadCertificate(o).then((response) => {
                commit('DOWNLOAD_CERTIFICATE', response.data)
                resolve(response.data);
            }, error => {
                reject(error);
            });
        });
    },
}

const getters = {
    getPermission :  state => state.permission,
    getPermissions : state => state.permissions,
    getUserOrders : state => state.user_orders,
    getUserProfile : state => state.user_profile,
    getUserAddresses : state => state.user_addresses,
    getValidLogin : state => state.validLogin,
    getJWTToken : state => state.jwt,
    getUser : state => state.user
}


const accountModule = {
    state,
    mutations,
    actions,
    getters
}
export default accountModule;