<template>
  <div>
    {{ getMessage('priceinfotext') }}
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'bottom-info-text-text-one',
  computed: {
    ...mapGetters(['getAppLanguage', 'getMessage']),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
