import languages from './languages.json';
import store from "@/store";

const translations = {};
languages.forEach((item) => {
    translations[item.language] = { name: item.name }
    translations[item.language].load = () => {
        return store.dispatch('loadTranslations', localStorage.getItem('VueAppLanguage'))
    };

});
export default translations;

