import Api from "./Api";
import Cookie from "js-cookie";

export default {
    getCookie() {
        let token = Cookie.get("XSRF-TOKEN");

        if (token) {
            return new Promise(resolve => {
                resolve(token);
            });
        }

        return Api.get("/init");
    },
    getNewCookie() {
        return new Promise((resolve, reject) => {
            Api.get("/init").then((response) => {
                resolve(response);
            }, error => {
                reject(error);
            })
        });
    }
};