<template>
  <div class="d-flex breakonmobile mt-1 justify-content-end" >
    <div class="errormsg" ref="errormsg" v-if="hasError"></div>

    <div class="mt-2 nomarginonmobile" style="margin-left: 15px;">
      <button class="btn btn-primary btn-lg btn-filled" v-on:click="createOrder">Kostenpflichtig bestellen</button>
    </div>
  </div>
</template>

<script>



import router from "@/router";
import {mapGetters} from "vuex";

export default {
  name: 'invoice-component',
  data () {
    return {
      locale: this.getAppLanguage,
      hasError:false
    };
  },
  computed: {
    ...mapGetters(['getMessage', 'getAppLanguage']),
  },
  methods: {
     createOrder() {
      this.loading = true;
      let o = {
        paymentType: 'invoice'
      }
      this.$store.dispatch("createOrder", o).then((response) => {
        this.loading = false
        this.$store.dispatch("getCartItems");
        router.push('/'+this.$i18n.locale+'/checkout/success/'+response.data.data.forwardUrl);
      }, async error => {
        // Error Message: Vin konnte nicht gefunden werden, bitte überüfen oder manuell ausfüllen
        this.hasError = true;
        this.$refs.errormsg.innerText = await this.getMessage('createorder');

        console.error(error);
        this.loading = false;
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
