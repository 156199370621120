import store from "@/store";
import {i18n} from "vue-lang-router";


const Translation = {
    getUserDefaultLanguage() {
        const getLanguage = () => (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || process.env.VUE_APP_I18N_LOCALE;
        const locale = getLanguage();
        const short = locale.split('-', 1)[0];
        if (localStorage.getItem('appLanguage') === null) {
            return short;
        }
        return localStorage.getItem('appLanguage');
    },
    routeMiddleware(to, from, next) {
        const locale = to.params.locale; // 1
        let activeLocale = localStorage.getItem('appLanguage');
        let langArr = ['bg','cz','de','en','fr','hr','hu','it','nl','pl','pt','pt','ro','sk','srb'];
        if(langArr.indexOf(locale) === -1) {
            return next();
        }
        console.log(locale);
        console.log(activeLocale);
        localStorage.setItem('appLanguage',locale);
        store.commit('setAppLanguage', locale);
        i18n.locale = locale;
        if(locale !== activeLocale) {
            store.dispatch('loadTranslations', locale);

        }

        // const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','); // 2
        // if (!supported_locales.includes(locale)) return next('en'); // 3
        // if (i18n.locale !== locale) { // 4
        //     i18n.locale = locale;
        //this.$store.commit('setAppLanguage', locale);
        //
        //}
        return next() // 5
    },
    replacer(tpl, obj) {
          let objKeys = Object.keys(obj);
          objKeys.forEach((itemKey) => {
              tpl = tpl.replace('{'+itemKey+'}',obj[itemKey]);
          })
          return tpl;
    }
}
export default Translation;