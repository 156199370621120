<template>
  <div class="dekra-background mb-4">
    <div class="dekra-cover">
      <div class="container">
        <h1 v-html=" header " />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'background-image-panel',
  props: {
    header: {
      type: String,
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
