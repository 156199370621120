<template>
  <div>
    <div class="errormsg" style="background-color: #01853c;" ref="errormsg" v-if="successMessage">{{ successMessage }}</div>
    <div class="errormsg" ref="errormsg" v-if="errorMessage">{{ errorMessage }}</div>
    <h4>{{ getMessage('profile') }}</h4>
    <div>
      <div style="border-bottom: 1px solid #E5E5E5; margin-bottom: 30px; padding-bottom: 30px;">
        <div class="row mt-3">
          <div class="col-md-6">
            <label>{{ getMessage('firstname') }}</label>
            <input class="form-control form-control-lg mt-1" name="Firstname" v-model="account.firstname"
                   type="text" :placeholder="getMessage('firstname') + ' *'">
          </div>
          <div class="col-md-6">
            <label>{{ getMessage('lastname') }}</label>
            <input class="form-control form-control-lg mt-1" name="Lastname" v-model="account.lastname"
                   type="text" :placeholder="getMessage('lastname') + ' *'">
          </div>
        </div>
        <div class="d-flex justify-content-start mt-3">
          <div>
            <button class="btn btn-primary btn-lg btn-filled" @click="saveProfile">{{ getMessage('save') }}</button>
          </div>
        </div>
      </div>
      <h4>Change Password</h4>
      <div>
        <div class="row">
          <div class="col-md-12">
            <input class="form-control form-control-lg mt-3" name="PasswordCurr" v-model="password.currentpassword"
                   type="password" :placeholder="getMessage('currentpassword') + ' *'">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <input class="form-control form-control-lg mt-3" name="Password" v-model="password.password"
                   type="password" :placeholder="getMessage('newpassword') + ' *'">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <input class="form-control form-control-lg mt-3" name="PasswordRepeat" v-model="password.passwordrepeat"
                   type="password" :placeholder="getMessage('repeatnewpassword') + ' *'">
          </div>
        </div>
        <div class="d-flex justify-content-start mt-3">
          <div>
            <button class="btn btn-primary btn-lg btn-filled" @click="changePassword">{{ getMessage('changepassword') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'account-profile-view',
  data () {
    return {
      user_profile: [],
      account: {
        firstname: this.$store.state.account.user.firstname,
        lastname: this.$store.state.account.user.lastname,
      },
      password: {
        currentpassword: "",
        password: "",
        passwordrepeat: "",
      },
      successMessage: "",
      errorMessage: ""
    }
  },
  computed: {
    ...mapGetters(['getMessage']),
  },
  methods: {
    saveProfile() {
      this.successMessage = ""
      this.errorMessage = ""

      let o = {
        'firstname': this.account.firstname,
        'lastname': this.account.lastname
      }
      this.$store.dispatch("saveUserProfile", o).then((result) => {
        this.successMessage = this.getMessage('userprofileupdatedsuccess')
        console.log(result)
      }, async error => {
        this.errorMessage = "Error"
        console.error(error);
      })
    },
    changePassword() {
      this.successMessage = ""
      this.errorMessage = ""

      let o = {
        'currentpassword': this.password.currentpassword,
        'password': this.password.password,
        'passwordrepeat': this.password.passwordrepeat
      }
      this.$store.dispatch("saveUserPassword", o).then((result) => {
        this.successMessage = this.getMessage('passwordchangesuccess')
        console.log(result)
      }, async error => {
        this.errorMessage = "Error"
        console.error(error);
      })
    }
  }
}
</script>

<style></style>