<template>
  <div>
    <MainNavigation />
    <BackgroundImagePanel min-height="50vh">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="dekra-bannerbg text-black" style="height: 100%;">
            <div id="registerheader">
              <h2 class="mt-0">{{ getMessage('createaccount') }}</h2>
              <div class="d-flex">
                <div class="pick-button active me-3" ref="company" v-on:click="setPage('company')">
                  <div><font-awesome-icon style="font-size: 28px;" icon="fa-solid fa-building" /></div>
                  <div>{{ getMessage('company') }}</div>
                </div>
                <div class="pick-button" ref="consumer" v-on:click="setPage('consumer')">
                  <div><font-awesome-icon style="font-size: 28px;" icon="fa-solid fa-user" /></div>
                  <div>{{ getMessage('consumer') }}</div>
                </div>

              </div>
            </div>
            <div
                :is="currentPage"
            >
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </BackgroundImagePanel>
    <Footer />
  </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation'
import Footer from '@/components/Footer'
import BackgroundImagePanel from "@/components/BackgroundImagePanel";
import ConsumerSignUp from "@/components/user/ConsumerSignUp";
import CompanySignUp from "@/components/user/CompanySignUp";
import {mapGetters} from "vuex";

export default {
  components: {
    MainNavigation, Footer, BackgroundImagePanel, ConsumerSignUp, CompanySignUp
  },
  data() {
    return {
      test: "testvalue",
      currentPage: "company-sign-up"
    }
  },
  computed: {
    ...mapGetters(['getMessage']),
  },
  methods: {
    setPage(page){
      switch (page) {
        case "consumer":
          this.currentPage = 'consumer-sign-up';
          if(!this.$refs.consumer.classList.contains('active')){
            this.$refs.consumer.classList.add('active')
          }
          if(this.$refs.company.classList.contains('active')){
            this.$refs.company.classList.remove('active');
          }
          break;
        case "company":
          this.currentPage = 'company-sign-up';
          if(this.$refs.consumer.classList.contains('active')){
            this.$refs.consumer.classList.remove('active')
          }
          if(!this.$refs.company.classList.contains('active')){
            this.$refs.company.classList.add('active');
          }
          break;
      }
    },
    setMessage(message){
      this.currentPage = message
      document.getElementById('registerheader').style.display = 'none'
    }
  },
}
</script>

<style></style>