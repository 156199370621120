import Vuex from 'vuex'
import Vue from 'vue'
import product from'./modules/product';
import cart from './modules/cart';
import language from './modules/language';
import order from './modules/order';
import country from './modules/country';
import account from "./modules/account";
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        product,
        cart,
        language,
        order,
        country,
        account
    }
})