<template>
    <div>
      {{ getMessage('infotext04') }}
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'bottom-info-text-text-four',
  computed: {
    ...mapGetters(['getAppLanguage', 'getMessage']),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
