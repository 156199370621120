<template>
  <div>
    <h2 class="mt-0 mb-4">{{ getMessageWithReplace('welcomename', {'name' : userName}) }}</h2>
    <div class="row">
      <div class="col-md-12">
        <h4>Offene Bestellungen</h4>
        <div style="position: relative;">
          <div v-if="!open_user_orders_loaded">
            <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%; z-index: 1000;">
              <font-awesome-icon style="font-size: 40px; color: #11843D;" icon="fa-solid fa-spinner" spin/>
            </div>
          </div>
          <div v-else>
            <div v-if="open_user_orders.length !== 0">
              <div class="order-item" v-for="item in open_user_orders" :key="item.id">
                <div class="row">
                  <div class="col-md-8">
                    <div class="pname">Order #{{ item.orderNr }}</div>
                  </div>
                  <div class="col-md-4 text-end">
                    <div class="pprice">€ {{ item.sum }}</div>
                  </div>
                  <div>
                    <table style="width: 100%;">
                      <tr v-for="product in item.orderProducts" :key="product.id">
                        <td style="max-width: 100px;">Umweltplakette Österreich</td>
                        <td style="max-width: 100px;">{{ product.vin }}</td>
  <!--                      <td style="max-width: 100px;">-->
  <!--                        <div v-if="product.state === 1">Created</div>-->
  <!--                        <div v-if="product.state === 2">Pending Payment</div>-->
  <!--                        <div v-if="product.state === 3">Completed</div>-->
  <!--                        <div v-if="product.state === 4">Processing</div>-->
  <!--                        <div v-if="product.state === 5">Reviewed</div>-->
  <!--                        <div v-if="product.state === 6">Assigned</div>-->
  <!--                        <div v-if="product.state === 7">On Hold</div>-->
  <!--                        <div v-if="product.state === 8">Delivery</div>-->
  <!--                        <div v-if="product.state === 9">Closed</div>-->
  <!--                        <div v-if="product.state === 15">Dismissed</div>-->
  <!--                        <div v-if="product.state === 16">Closed (Dismissed)</div>-->
  <!--                        <div v-if="product.state === 20">Booked</div>-->
  <!--                        <div v-if="product.state === 99">Deleted</div>-->
  <!--                      </td>-->
                        <td style="max-width: 50px;">
                          <div v-if="product.state === 9">
                          </div>
                        </td>
                        <td style="text-align: right;">€ {{ formatMoney(product.price, 2, '.', ',') }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              {{ getMessage('noordersfound') }}
            </div>
          </div>
        </div>
        <h4 class="mt-5">{{ getMessage('lastorders') }}</h4>
        <div style="position: relative;">
          <div v-if="!last_user_orders_loaded">
            <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%; z-index: 1000;">
              <font-awesome-icon style="font-size: 40px; color: #11843D;" icon="fa-solid fa-spinner" spin/>
            </div>
          </div>
          <div v-else>
            <div v-if="last_user_orders.length !== 0">
              <div class="order-item" v-for="item in last_user_orders" :key="item.id">
                <div class="row">
                  <div class="col-md-8">
                    <div class="pname">Order #{{ item.orderNr }}</div>
                  </div>
                  <div class="col-md-4 text-end">
                    <div class="pprice">€ {{ item.sum }}</div>
                  </div>
                  <div>
                    <table style="width: 100%;">
                      <tr v-for="product in item.orderProducts" :key="product.id">
                        <td style="max-width: 100px;">Umweltplakette Österreich</td>
                        <td style="max-width: 100px;">{{ product.vin }}</td>
                        <td style="max-width: 100px;">
                          <div v-if="product.state === 1">Created</div>
                          <div v-if="product.state === 2">Pending Payment</div>
                          <div v-if="product.state === 3">Completed</div>
                          <div v-if="product.state === 4">Processing</div>
                          <div v-if="product.state === 5">Reviewed</div>
                          <div v-if="product.state === 6">Assigned</div>
                          <div v-if="product.state === 7">On Hold</div>
                          <div v-if="product.state === 8">Delivery</div>
                          <div v-if="product.state === 9">Closed</div>
                          <div v-if="product.state === 15">Dismissed</div>
                          <div v-if="product.state === 16">Closed (Dismissed)</div>
                          <div v-if="product.state === 20">Booked</div>
                          <div v-if="product.state === 99">Deleted</div>
                        </td>
                        <td style="max-width: 50px;">
                          <div v-if="product.state === 9">
                            <a href="#" @click="downloadCertificate(product.id)"><font-awesome-icon icon="fa-solid fa-download" fixed-width />&nbsp;Zertifikat</a>
                          </div>
                        </td>
                        <td style="text-align: right;">€ {{ formatMoney(product.price, 2, '.', ',') }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="download" style="margin-top: 5px;">
                    <a href="#" @click="downloadInvoice(item.id)"><font-awesome-icon icon="fa-solid fa-download" fixed-width />&nbsp;Rechnung</a>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              {{ getMessage('noordersfound') }}
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="col-md-4">
        <h4>{{ getMessage('credit') }}</h4>
        <div class="row">
          <div class="col-md-8" style="color: #01853C; font-weight: bold;">
            Umweltplaketten
          </div>
          <div class="col-md-4 text-end" style="color: #01853C; font-weight: bold;">
            €750,00
          </div>
        </div>
        <p style="margin-top: 5px; font-size: 14px; color: #8B8B8B;">{{ getMessage('creditdescription') }}</p>
        <button class="btn btn-primary btn-filled w-100">{{ getMessage('topupcredit') }}</button>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'account-overview-view',
  computed: {
    ...mapGetters(['getMessage','getMessageWithReplace','getUser']),
  },
  data() {
    return {
      userName: this.$store.state.account.user.firstname+' '+this.$store.state.account.user.lastname,
      open_user_orders: [],
      open_user_orders_loaded: false,
      last_user_orders: [],
      last_user_orders_loaded: false,
      api_url: '',
    }
  },
  mounted() {
    this.api_url = process.env.VUE_APP_API_URL
    this.getOpenOrders()
    this.getLastOrders()
  },
  methods: {
    getOpenOrders(){
      this.$store.dispatch("loadOpenUserOrders").then((result) => {
        this.open_user_orders = result
        this.open_user_orders_loaded = true
        console.log(result)
      }, async error => {
        console.error(error);
      })
    },
    getLastOrders(){
      this.$store.dispatch("loadLastUserOrders").then((result) => {
        this.last_user_orders = result
        this.last_user_orders_loaded = true
        console.log(result)
      }, async error => {
        console.error(error);
      })
    },
    formatMoney(number, decPlaces, decSep, thouSep) {
      decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
          decSep = typeof decSep === "undefined" ? "." : decSep;
      thouSep = typeof thouSep === "undefined" ? "," : thouSep;
      var sign = number < 0 ? "-" : "";
      var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
      var j = (j = i.length) > 3 ? j % 3 : 0;

      return sign +
          (j ? i.substr(0, j) + thouSep : "") +
          i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
          (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    },
    downloadInvoice (itemId){
      let options = {};
      options.itemId = itemId
      this.$store.dispatch("downloadInvoice", options).then((result) => {
        let blob = new Blob([result], {type: 'application/pdf'});
        let fileURL = window.URL.createObjectURL(blob);
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'invoice_'+itemId+'.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      }, async error => {
        console.error(error);
      })
      return true;
    },
    downloadCertificate (itemId){
      let options = {};
      options.itemId = itemId
      this.$store.dispatch("downloadCertificate", options).then((result) => {
        let blob = new Blob([result], {type: 'application/pdf'});
        let fileURL = window.URL.createObjectURL(blob);
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'certificate_'+itemId+'.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      }, async error => {
        console.error(error);
      })
      return true;
    }

  }
}

</script>

<style></style>