<template>
  <div>
    <MainNavigation/>
    <BackgroundImagePanel>
      <div class="row">
        <div class="col-md-12">
          <div class="dekra-bannerbg" style="height: 100%;">
            <div class="row">
              <div class="col-md-3">
                <AccountNavigationView />
              </div>
              <div class="col-md-9">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImagePanel>
    <Footer/>
  </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation'
import Footer from '@/components/Footer'
import BackgroundImagePanel from '@/components/BackgroundImagePanel'
import {mapGetters} from "vuex";
import AccountNavigationView from "@/components/account/AccountNavigationView";

export default {
  name: 'account-overview-view',
  components: {
    AccountNavigationView,
    MainNavigation, Footer, BackgroundImagePanel
  },
  computed: {
    ...mapGetters(['getMessage']),
  },
}
</script>

<style></style>