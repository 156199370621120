import Api from "./Api";
import Csrf from "@/services/Csrf";

export default {
    async getNewProduct(o) {
        await Csrf.getCookie();
        return  Api.get('/order/new/product/' + o.vin, {
            headers: {
                'dekra': o.dekra
            }

        });
    },
    async uploadProductFile(o) {
        let token = await Csrf.getNewCookie();
        return Api.post('order/new/product/' + o.vin + '/file', o.formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-CSRF-TOKEN': token.data
                }
            });
    }

};