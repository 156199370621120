<template>
  <div>
    <MainNavigation/>
    <BackgroundImagePanel :header="getMessage('headline1')">
      <div class="row">
        <div class="col-md-8">
          <div class="dekra-bannerbg" style="height: 100%;">
            <h3 class="mt-0">{{ getMessage('cart') }}</h3>
            <div :hidden="cartEmpty">
             <CartList />
              <div class="d-flex justify-content-end mt-5">
                <div>
                  <router-link target="_self" class="btn btn-primary btn-lg" to="/">{{ getMessage('addplaque') }}</router-link>
                  <button class="btn btn-primary btn-lg btn-filled ms-2" v-on:click="sendCheckout">{{ getMessage('pay') }}</button>
                </div>
              </div>
            </div>
            <div :hidden="!cartEmpty">
              <div>
                <p>{{ getMessage('cartempty') }}</p>
              </div>
              <div class="d-flex justify-content-end mt-5">

                <router-link target="_self" class="btn btn-primary btn-lg" to="/">{{ getMessage('addplaque') }}</router-link>
              </div>
            </div>


          </div>
        </div>
        <div class="col-md-4">
          <div class="dekra-bannerbg" style="height: 100%;">

          </div>
        </div>
      </div>
    </BackgroundImagePanel>
    <BottomInfoText/>
    <Footer/>
  </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation'
import Footer from '@/components/Footer'
import BackgroundImagePanel from "@/components/BackgroundImagePanel";
import BottomInfoText from "@/components/BottomInfoText";
import CartList from "@/components/cart/List";
import {mapGetters, mapActions} from 'vuex';
import router from "@/router";

export default {
  components: {
    MainNavigation, BottomInfoText, Footer, BackgroundImagePanel, CartList
  },
  data() {
    return {
      component: 'cart-list',
    }
  },

  computed: {
    ...mapGetters(['cartItems', 'cartInfo', 'getMessage']),
    ...mapActions(['getCartItems']),
    cartEmpty() {
      return this.cartInfo.count === 0;

    }
  },
  methods: {

    sendCheckout() {
      this.$gtm.trackEvent(
          {
            event: null, // Event type [default = 'interaction'] (Optional)
            category: "Cart",
            action: "toCheckout",
            label: " Send to Checkout from Basket",
            value: 1,
            noninteraction: false, // Optional
          }
      );
      router.push('/'+this.$i18n.locale+'/checkout/address');
    },
    sendShop() {
      this.$gtm.trackEvent(
          {
            event: null, // Event type [default = 'interaction'] (Optional)
            category: "Cart",
            action: "addProduct",
            label: " Add new Product",
            value: 1,
            noninteraction: false, // Optional
          }
      );
      this.$parent.$emit('shop-start-view', {})
    },

  },
  mounted() {
    this.$gtm.trackView('Cart View', 'currentPath');
  },

}
</script>

<style></style>