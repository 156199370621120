<template>
  <div>
    <LoadingSpinner v-if="loading"/>
    <form id="payment-form">
      <div id="error-message" v-show="paymentError"
           style="background-color: #df1b41; padding: 15px; border-radius: 4px; margin-bottom: 20px; color: #FFF; ">
        <div v-html="errorMessageText"></div>
      </div>
      <div id="payment-element">
        <!-- Elements will create form elements here -->
      </div>
      <div class="mb-4 mt-4 text-right col-md-12">
        <span v-html="getMessage('acceptagb')"></span>
      </div>
      <div class="d-flex breakonmobile mt-1 justify-content-end">

        <div class="mt-2 nomarginonmobile" style="margin-left: 15px;">

          <button class="btn btn-primary btn-lg btn-filled" id="submit" :disabled="btndisabled">{{
              getMessage('paynow')
            }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Payment from "@/services/Payment";
import {loadStripe} from "@stripe/stripe-js";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: 'stripe-component',
  props: ['elements', 'stripe'],
  computed: {
    ...mapGetters(['cartItems', 'getAppLanguage', 'getMessage', 'orderId', 'orderHash']),
  },
  components: {
    LoadingSpinner
  },
  data() {
    return {
      loading: false,
      locale: this.getAppLanguage,
      errorMessageText: '',
      paymentError: false,
      btndisabled: true
    };
  },
  methods: {
    setPaymentError(v) {
      this.paymentError = v;
    },
    finishLoading() {
      this.$emit('finished-loading')
    }
  },
  mounted() {
    this.$gtm.trackView('Payment Stripe', 'currentPath');
    // var elements;
    var stripe;
    var clientSecret;
    var elements;
    var i18locale = this.$i18n.locale;
    async function initialize(self) {
      stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);
      const response = Payment.createPaymentIntent().then((result) => {
        clientSecret = result.data.data;
        const appearance = {
          theme: 'stripe',
        };
        elements = stripe.elements({appearance, clientSecret});
        elements.update({locale: i18locale});
        const paymentElement = elements.create("payment");
        //paymentElement.mount("#payment-element"); by Sebastian, first add event listener and than add it to the dome, otherwise we could mis events!
        paymentElement.on('ready', () => {
          self.finishLoading()
        });
        paymentElement.on('change', (event) => {
          if (event.complete === true) {
            self.btndisabled = false
          } else {
            self.btndisabled = true
          }
        })
		paymentElement.mount("#payment-element");

      });
      console.log(response);
    }

    initialize(this);
    checkStatus();

    var self = this;

    async function handleSubmit(e) {
      self.btndisabled = true;
      self.loading = true;
      e.preventDefault();
      self.setPaymentError(false);
      //if (self.$store.getters.orderId === null) {
        let o = {
          "paymentType": "stripe"
        };
        self.$store.dispatch("createOrder", o).then(async response => {
          self.errorMessageText = '';
          await confirmPayment(response.data.data.orderHash)
        }, async error => {
          self.haserror = true;
          self.$refs.errormsg.innerText = error.msg;
          self.loading = false;
        })
      //}
      /*
      else {
        self.setPaymentError(true);
        self.errorMessageText = "An unexpected error occured. (Error 967)";
        self.loading = false;
        if (self.loading) {
          await confirmPayment('response.data.data.orderHash')
        }
      }*/
    }

    async function confirmPayment(orderHash) {
      const url = process.env.VUE_APP_STRIPE_URL + '/?i=' + orderHash;
      self.$gtm.trackEvent(
          {
            event: null, // Event type [default = 'interaction'] (Optional)
            category: "Checkout",
            action: "confirmPaymentStripe",
            label: "Confirm Stripe Payment",
            value: 1,
            noninteraction: false, // Optional
          }
      );
      const {error} = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: url
        },
      });
      self.loading = false;
      self.setPaymentError(true);
      if (error.type === "card_error" || error.type === "validation_error") {

        self.errorMessageText = error.message;
      } else {
        self.errorMessageText = "An unexpected error occured.";
      }
    }


    async function checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const {paymentIntent} = await stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          showMessage("Payment succeeded!");
          break;
        case "processing":
          showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          showMessage("Your payment was not successful, please try again.");
          break;
        default:
          showMessage("Something went wrong.");
          break;
      }
    }


    function showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");

      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;

      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageText.textContent = "";
      }, 4000);
    }


    document
        .querySelector("#payment-form")
        .addEventListener("submit", handleSubmit);

    // (async () => {
    //   // const { client_secret: clientSecret } = await fetch('http://frontend.dekra.apollo7.work:3000/secret').then(r => r.json());
    //
    //   // Module Appearance
    //   const appearance = {
    //
    //   }
    //
    //   // From Server
    //   const options = {
    //     clientSecret,
    //     locale: this.$store.getters.getAppLanguage,
    //     loader: 'auto',
    //     appearance
    //   };
    //
    //   // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
    //   const elements = stripe.elements(options);
    //
    //   // Create and mount the Payment Element
    //   const paymentElement = elements.create('payment');
    //   paymentElement.mount('#payment-element');
    //
    //   const form = document.getElementById('payment-form');
    //   form.addEventListener('submit', async (event) => {
    //     event.preventDefault();
    //
    //     const {error} = await stripe.confirmPayment({
    //       //`Elements` instance that was used to create the Payment Element
    //       elements,
    //       confirmParams: {
    //         return_url: 'http://frontend.dekra.apollo7.work:8080/',
    //       },
    //     });
    //
    //     if (error) {
    //       // This point will only be reached if there is an immediate error when
    //       // confirming the payment. Show error to your customer (for example, payment
    //       // details incomplete)
    //       const messageContainer = document.querySelector('#error-message');
    //       messageContainer.style.display = 'block';
    //       messageContainer.textContent = error.message;
    //     } else {
    //       // Your customer will be redirected to your `return_url`. For some payment
    //       // methods like iDEAL, your customer will be redirected to an intermediate
    //       // site first to authorize the payment, then redirected to the `return_url`.
    //     }
    //   });
    // })();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
