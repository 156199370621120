<template>
  <div class="row">

    <div class="col-md-8" style="height: 100%;">
      <div class="dekra-bannerbg">
        <div class="errormsg" ref="errormsg" v-if="haserror">{{ errorMessage }}</div>
        <h3 class="mt-0">{{ getMessage('deliveryinvoiceaddress') }}</h3>
        <div>
          <div>
            <div class="d-flex mb-2" style="flex-wrap: wrap;">
              <div style="margin-right: 4rem;">
                <div class="form-check d-flex align-items-center">
                  <input type="radio"  class="form-check-input" value="1" id="business" v-model="delivery.business"/><label for="business" class="form-check-label"> {{ getMessage('business') }}</label>
                </div>
              </div>
              <div>
                <div class="form-check d-flex align-items-center">
                  <input type="radio"  class="form-check-input" value="0" id="personal" v-model="delivery.business"/> <label for="personal" class="form-check-label"> {{ getMessage('personal') }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-5" v-if="delivery.business == 1">
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" v-model="delivery.company" v-validate="'required'" name="Company" type="text"
                     :placeholder="getMessage('companyaddress')">

            </div>
          </div>
          <div class="row ">
          <h5 class="mt-1 ">{{ getMessage('personalinformation') }}</h5>
          </div>

          <div class="row mt-2">
            <div class="col-md-12">
              <select class="form-select form-select-lg" v-model="delivery.salutation" name="Salutation"
                      v-validate="'required'" style="max-width: 320px;">
                <option :value="null" disabled>{{ getMessage('salutation') }} *</option>
                <option value="1">{{ getMessage('female') }}</option>
                <option value="2">{{ getMessage('male') }}</option>
              </select>
              <span> {{ errors.first('Salutation') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" v-model="delivery.firstname" name="Firstname"
                     v-validate="'required'" type="text" :placeholder="getMessage('firstname') + ' *'">
              <span> {{ errors.first('Firstname') }}</span>

            </div>
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" v-model="delivery.lastname" name="Lastname"
                     v-validate="'required'" type="text" :placeholder="getMessage('lastname') + ' *'">
              <span> {{ errors.first('Lastname') }}</span>

            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <input class="form-control form-control-lg mt-3" v-model="delivery.street" name="Street"
                     v-validate="'required'" type="text" :placeholder="getMessage('street') + ' *'">
              <span>{{ errors.first('Street') }}</span>

            </div>
            <div class="col-md-4">
              <input class="form-control form-control-lg mt-3" v-model="delivery.number" name="Number"
                     v-validate="'required'" type="text" :placeholder="getMessage('nr') + ' *'">
              <span> {{ errors.first('Number') }}</span>

            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" v-model="delivery.zip" name="Zip" v-validate="'required'"
                     type="text" :placeholder="getMessage('zip') + ' *'">
              <span> {{ errors.first('Zip') }}</span>

            </div>
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" v-model="delivery.city" name="City" v-validate="'required'"
                     type="text" :placeholder="getMessage('city') + ' *'">
              <span> {{ errors.first('City') }}</span>

            </div>

          </div>
          <div class="row">
            <div class="col-md-6">
              <select class="form-select form-select-lg mt-3" v-validate="'required'" name="Country"
                      v-model="delivery.country" @change="currentCountryEU">
                <option :value="null" disabled>{{ getMessage('country') }} *</option>


                <option
                    v-for="(country, index) of countryItems"
                    :key="index"
                    :value="country.key"
                >{{ country.label }}
                </option>
              </select>
              <span> {{ errors.first('Country') }}</span>
            </div>
            <div class="col-md-6">
              <div v-if="currentEU">
                <input class="form-control form-control-lg mt-3" v-model="delivery.vat" ref="delivery.country" name="VAT" id="delVAT" type="text"
                       :placeholder="getMessage('uid')">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" name="Phone" v-validate="'required'"
                     v-model="delivery.phone" type="text" :placeholder="getMessage('phone') + ' *'">
              <span> {{ errors.first('Phone') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" name="MemberId" v-model="delivery.member_id" v-validate="'numeric'" type="text" :placeholder="'Member ID'">
              <span> {{ errors.first('MemberId') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <input class="form-control form-control-lg mt-3" name="Email" v-model="delivery.email"
                     v-validate="'required|email'" type="email" :placeholder="getMessage('email') + ' *'">
              <span :show="errors.length > 0"> {{ errors.first('Email') }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">

              <b-form-checkbox id="checkbox-1"  name="seperateaddress" ref="seperateAddress" v-model="seperateaddress"
                               :value="true" :unchecked-value="false">
                &nbsp;{{ getMessage('differentbilling') }}
              </b-form-checkbox>

            </div>
          </div>
        </div>
        <div v-if="seperateaddress">
          <h3>{{ getMessage('invoiceaddress') }}</h3>
          <div class="row mb-5" v-if="delivery.business == 1">
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" v-model="invoice.company" type="text"  v-validate="'required'"  name="Billing Address | Company"
                     :placeholder="getMessage('companyaddress')">
              <span> {{ errors.first('Billing Address | Company') }}</span>
            </div>

          </div>
          <h5 class="mt-1 ">Personal Information</h5>
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="col-md-12">
                <select class="form-select form-select-lg" v-model="invoice.salutation" name="Salutation"
                        v-validate="'required'" style="max-width: 320px;">
                  <option :value="null" disabled>{{ getMessage('salutation') }} *</option>
                  <option value="1">{{ getMessage('female') }}</option>
                  <option value="2">{{ getMessage('male') }}</option>
                </select>
                <span> {{ errors.first('Salutation') }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" v-validate="'required'" name="Billing Address | Firstname"
                     v-model="invoice.firstname" type="text" :placeholder="getMessage('firstname') + ' *'">
              <span> {{ errors.first('Billing Address | Firstname') }}</span>
            </div>
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" v-model="invoice.lastname" type="text"  v-validate="'required'"  name="Billing Address | Lastname"
                     :placeholder="getMessage('lastname') + ' *'">
              <span> {{ errors.first('Billing Address | Lastname') }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <input class="form-control form-control-lg mt-3" v-model="invoice.street" type="text"  v-validate="'required'"  name="Billing Address | Street"
                     :placeholder="getMessage('street') + ' *'">
              <span> {{ errors.first('Billing Address | Street') }}</span>
            </div>
            <div class="col-md-4">
              <input class="form-control form-control-lg mt-3" v-model="invoice.number" type="text"  v-validate="'required'"  name="Billing Address | Nr"
                     :placeholder="getMessage('nr') + ' *'">
              <span> {{ errors.first('Billing Address | Nr') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" v-model="invoice.zip" type="text"  v-validate="'required'"  name="Billing Address | ZIP"
                     :placeholder="getMessage('zip') + ' *'">
              <span> {{ errors.first('Billing Address | ZIP') }}</span>
            </div>
            <div class="col-md-6">
              <input class="form-control form-control-lg mt-3" v-model="invoice.city" type="text"  v-validate="'required'"  name="Billing Address | City"
                     :placeholder="getMessage('city') + ' *'">
              <span> {{ errors.first('Billing Address | City') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <select class="form-select form-select-lg mt-3" v-model="invoice.country"  v-validate="'required'"  name="Billing Address | Country">
                <option :value="null" disabled>{{ getMessage('country') }} *</option>
                <option
                    v-for="(country, index) of countryItems"
                    :key="index"
                    :value="country.key"
                >{{ country.label }}
                </option>
              </select>
              <span> {{ errors.first('Billing Address | Country') }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <input class="form-control form-control-lg mt-3"  v-model="invoice.email" type="email" v-validate="'required|email'"  name="Billing Address | Email"
                     :placeholder="getMessage('email') + ' *'">
              <span> {{ errors.first('Billing Address | Email') }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <div>
            <button class="btn btn-primary btn-lg btn-filled" v-on:click="saveAddress">{{ getMessage('next') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="dekra-bannerbg" style="height: 100%;">
        <h3 class="mt-0">{{ getMessage('cart') }}:</h3>
        <CartList is-checkout="true"/>
      </div>
    </div>

  </div>


</template>

<script>
import CartList from "@/components/cart/List";
import router from "@/router";
import { Validator } from 'vee-validate';
import {mapGetters} from "vuex";


export default {
  name: 'checkout-address-view',
  components: {
    CartList
  },
  data() {
    return {
      vinval: this.vin,
      seperateaddress: false,
      currentEU: false,
      delivery: {
        // salutation: null,
        // firstname: 'Raphael',
        // lastname: 'Malacek',
        // company: 'Raphael Malacek',
        // vat: '',
        // street: 'In der Stift',
        // number: '1',
        // zip: '3492',
        // city: 'Wien',
        // country: null,
        // phone: '+43664123456789',
        // email: 'raphael@malacek.at',
        // business: 1,
        salutation: null,
        firstname: null,
        lastname: null,
        company: null,
        vat: null,
        street: null,
        number: null,
        zip: null,
        city: null,
        country: null,
        phone: null,
        email: null,
        member_id: null,
        business: 1,
      },
      invoice: {
        salutation: '',
        firstname: null,
        lastname: null,
        company: null,
        vat: null,
        street: null,
        number: null,
        zip: null,
        city: null,
        country: null,
        phone: null,
        email: null
      },
      haserror: false,
      errorMessage: '',
    }

  },
  computed: {
    ...mapGetters(['countryItems', 'getMessage','getBillingAddress','getDeliveryAddress','hasDeliveryAddress'])
  },
  mounted() {

    let hash = location.hash;
    if((hash && hash === '#edit') || this.$store.getters.hasDeliveryAddress ) {

        this.delivery = this.$store.state.cart.deliveryAddress;
        this.invoice = this.$store.state.cart.billingAddress;

        if(this.isEU(this.delivery.country)){
          this.currentEU = true;
        }
    }
    this.validationErrors()
  },
  methods: {
    saveAddress() {
      this.haserror = false;
      this.errorMessage = '';

      this.$validator.validate().then(valid => {
        if (valid) {
          this.loading = true;

          this.checkVat().then((data) => {
            if(data){
              let o = {
                delivery: this.delivery,
                invoice:null
              }
              if(this.seperateaddress) {
                o.invoice = this.invoice;
              }
              this.$store.dispatch("setAddresses", o).then(() => {
                this.loading = false
                this.$gtm.trackEvent(
                    {
                      event: null, // Event type [default = 'interaction'] (Optional)
                      category: "Checkout",
                      action: "toPayment",
                      label: "Add Addresses",
                      value: 1,
                      noninteraction: false, // Optional
                    }
                );
                router.push('/'+this.$i18n.locale+'/checkout/payment');
              }, async error => {
                // Error Message: Vin konnte nicht gefunden werden, bitte überüfen oder manuell ausfüllen
                this.haserror = true;
                this.$refs.errormsg.innerText = await this.getMessage('vinnotfound');

                console.error(error);
                this.loading = false;
              })
            }
            this.loading = false;
          });
        }
      });

    },
    checkVat(){
      this.loading = true;
      return new Promise((resolve) => {
        let self = this;

        if(this.currentEU && parseInt(this.delivery.business) === 1){
          if(this.delivery.vat !== '' && this.delivery.vat !== null){
            this.$store.dispatch("checkVat", this.delivery.vat).then(function (response) {
              if(response.status === 200){
                resolve(true);
              } else {
                self.haserror = true;
                self.errorMessage = response.data.message;
                this.loading = false;
                resolve(false);
              }
            }).catch(function (error) {
              if(error.response.status === 400){
                self.haserror = true;
                self.errorMessage = error.response.data;
                this.loading = false;
                resolve(false);
              }
            });
          } else {
            this.loading = false;
            resolve(true);
          }
        } else {
          this.loading = false;
          resolve(true);
        }
      })
    },
    saveAddressTest() {
      this.$validator.validate().then(valid => {
        if (valid) {

          this.loading = true;
          let o = {
            delivery: this.delivery,
            invoice:null
          }
          if(this.seperateaddress) {
            o.invoice = this.invoice;
          }
          this.$store.dispatch("setAddresses", o).then(() => {
            this.loading = false
          }, async error => {
            // Error Message: Vin konnte nicht gefunden werden, bitte überüfen oder manuell ausfüllen
            this.haserror = true;
            this.$refs.errormsg.innerText = error.msg;

            console.error(error);
            this.loading = false;
          })

        }
      });

    },
    currentCountryEU(){
      let countries = this.countryItems;

      var filteredArray = countries.filter((item) => {
        return item.key === this.delivery.country
      });

      this.currentEU = filteredArray[0].eu;
    },
    isEU(country){
      let countries = this.countryItems;

      var filteredArray = countries.filter((item) => {
        return item.key === country
      });

      return filteredArray[0].eu;
    },
    validationErrors(){
      var sprintf = (str, ...argv) => !argv.length ? str : sprintf(str = str.replace(sprintf.token||"%s", argv.shift()), ...argv);

      const dict = {
        custom: {
          Salutation: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('salutation'))
          },
          Firstname: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('firstname'))
          },
          Lastname: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('lastname'))
          },
          Street: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('street'))
          },
          Number: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('nr'))
          },
          Zip: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('zip'))
          },
          City: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('city'))
          },
          Country: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('country'))
          },
          Phone: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('phone'))
          },
          Email: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('email')),
            email: () => sprintf(this.getMessage('emailinvalid'), this.getMessage('email'))
          },
          'Billing Address | Company': {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('companyaddress'))
          },
          'Billing Address | Firstname': {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('firstname'))
          },
          'Billing Address | Lastname': {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('lastname'))
          },
          'Billing Address | Street': {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('street'))
          },
          'Billing Address | Nr': {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('nr'))
          },
          'Billing Address | ZIP': {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('zip'))
          },
          'Billing Address | City': {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('city'))
          },
          'Billing Address | Country': {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('country'))
          },
          'Billing Address | Email': {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('email')),
            email: () => sprintf(this.getMessage('emailinvalid'), this.getMessage('email'))
          },
        }
      };

      Validator.localize('en', dict);
    },

    // validations () {
    //   return {
    //     delVAT: {
    //       mustBeCool
    //     }
    //   }
    // }
    created () {


    },
    mounted() {
      this.$gtm.trackView('Delivery Address', 'currentPath');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input:valid {
  border-color: green;
}

input:invalid {
  border-color: red;
}
</style>
