import langs from '../../../messages/langs.json'
import Country from "@/services/Country";
import Translation from "@/services/Translation";

const languageModule = {
    state: {
        translations: {},
        activeTranslation: {},
        availableLanguages: [],
        appLanguage: localStorage.getItem('VueAppLanguage') || 'en',
        appLanguages: langs,
    },
    mutations: {
        setAppLanguage(state, language) {
            state.appLanguage = language;
            localStorage.setItem('VueAppLanguage', language)
        },
        SET_TRANSLATION(state, translationList){
            state.translations[state.appLanguage] = translationList.messages;
            state.availableLanguages = translationList.available;
            state.activeTranslation = translationList.messages;
        }
    },
    actions: {
        loadTranslations ({ commit }, language) {
            let loadTranslation = true;
            return new Promise((resolve, reject) => {
                Country.setCurrentLanguage(language, loadTranslation).then(( response) => {
                    commit('SET_TRANSLATION', response.data.data)
                    resolve(response);
                }, error => {
                    reject(error);
                });
            });
        }
    },
    modules: {

    },
    getters: {
        getAppLanguage: (state) => state.appLanguage,
        getAppLanguages: (state) => state.appLanguages,
        getTranslation: (state) => state.translations,
        getActiveTranslation: (state) => state.activeTranslation,

        getAvailableLanguages: (state) => state.availableLanguages,
        getMessage: (state) => (key) =>  {
            if(state.activeTranslation[key]){
                return state.activeTranslation[key];
            }
            return key;
        },
        getMessageWithReplace: (state) => (key, obj) =>  {
            if(state.activeTranslation[key]){
                let string = state.activeTranslation[key];
                string = Translation.replacer(string, obj)
                return string;
            }
            return key;
        }
    }
}

export default languageModule;