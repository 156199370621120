import Api from "./Api";
import Csrf from "@/services/Csrf";

export default {
    async createPaymentIntent() {
        let token = await Csrf.getCookie();
        return new Promise((resolve, reject) => {
            Api.get('/payment/create',
                {
                    headers: {
                        'X-CSRF-TOKEN': token.data
                    }
                }).then((response) => {
                resolve(response);
            }, error => {
                reject(error);
            });
        });
        // return Api.get('/payment/create',
        //     {
        //         headers: {
        //             'X-CSRF-TOKEN': token.data
        //         }
        //     });
    },

};