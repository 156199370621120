<template>
  <div>
    {{ getMessage('infotitle04') }}
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'bottom-info-text-title-four',
  computed: {
    ...mapGetters(['getAppLanguage', 'getMessage']),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
