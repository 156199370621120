<template>
  <div>
    <div style="position: relative;">
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="dekra-bannerbg" style="height: 100%;">
              <h3 class="mt-0">{{ getMessage('overview') }}</h3>
              <div class="col-md-12">
                <h4 class="mt-0">{{ getMessage('cart') }}</h4>
                <hr>
                <CartList is-checkout="true" />
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h3>{{ getMessage('deliveryaddress') }}</h3>
                  <p>
                    {{ getDeliveryAddress.company }}<br/>
                    {{ deliveryAddressSalutation }} {{ getDeliveryAddress.firstname }} {{ getDeliveryAddress.lastname }}<br/>
                    {{ getDeliveryAddress.street }} {{ getDeliveryAddress.number }} , {{ getDeliveryAddress.zip }}  {{ getDeliveryAddress.city }} <br/>
                    {{ countryNameById(getDeliveryAddress.country) }} <br/>
                    {{ getDeliveryAddress.vat}}

                  </p>
                </div>
                <div class="col-md-6">
                  <h3>{{ getMessage('invoiceaddress') }}</h3>
                  <p>
                    {{ getBillingAddress.company }}<br/>
                    {{ billingAddressSalutation }} {{ getBillingAddress.firstname }} {{ getBillingAddress.lastname }}<br/>
                    {{ getBillingAddress.street }} {{ getBillingAddress.number }} , {{ getBillingAddress.zip }}  {{ getBillingAddress.city }} <br/>
                    {{ countryNameById(getBillingAddress.country) }}
                  </p>
                </div>
              </div>
              <router-link target="_self" :to="{ name: 'Address', params: { locale: this.$i18n.locale }, hash: '#edit'}">{{ getMessage('edit') }} <font-awesome-icon style="font-size: 14px; color: #11843D;" icon="fa fa-pen" /></router-link>

              <h3>{{ getMessage('shipment') }}</h3>
              <b-form-checkbox id="checkbox-1" name="checkbox-1" v-model="delivery_post" :value="true" :unchecked-value="false" disabled>
                &nbsp;{{ getMessage('postshipping') }}
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-12">
            <div class="dekra-bannerbg" style="height: 100%;">
              <h3 class="mt-0">{{ getMessage('payment') }}</h3>
              <div class="mb-4" v-if="showPaymentSelection">
                <div v-for="(paymentType, index) in getPaymentTypes" v-bind:key="index">
                  <b-form-radio type="radio" :id="'paymentType'+index" v-on:change="showPayment" :name="'paymentTypeGroup'" :disabled="paymentType.disabled" v-model="selectedPayment" :value="paymentType.value">
                    {{ paymentType.name }}
                  </b-form-radio>
                </div>
              </div>

              <component :is="currentPaymentComponent" v-on:finished-loading="loading=false"></component>

            </div>
          </div>
        </div>
      </div>
      <LoadingSpinner v-if="loading" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CartList from "@/components/cart/List";
import Stripe from "@/components/checkout/payment/StripeComponent";
import Invoice from "@/components/checkout/payment/InvoiceComponent";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: 'checkout-payment-view',
  components: {
    CartList, Invoice, Stripe, LoadingSpinner
  },
  computed: {
    ...mapGetters(['getPaymentTypes','getDeliveryAddress','getBillingAddress','countryNameById', 'getMessage','getUser']),
  },
  data() {
    return {
      selectedPayment: '',
      delivery_post: true,
      loading: false,
      deliveryAddressSalutation: '',
      billingAddressSalutation: '',
      currentPaymentComponent:  null,
      showPaymentSelection: false
    }
  },
  mounted(){
    if(this.getDeliveryAddress.salutation === '1'){
      this.deliveryAddressSalutation = this.getMessage('female')
    } else if(this.getDeliveryAddress.salutation === '2'){
      this.deliveryAddressSalutation = this.getMessage('male')
    }

    if(this.getBillingAddress.salutation === '1'){
      this.billingAddressSalutation = this.getMessage('female')
    } else if(this.getBillingAddress.salutation === '2'){
      this.billingAddressSalutation = this.getMessage('male')
    }
    this.$gtm.trackView('Payment View', 'currentPath');
  },
  beforeMount() {
    let user = this.$store.getters.getUser;
    if (user && this.getBillingAddress.vat) {
      this.currentPaymentComponent = null;
      this.showPaymentSelection = true;
    }
    else {
      this.currentPaymentComponent = Stripe;
      this.showPaymentSelection = false;
    }
  },
  methods: {
    addPlaque(){
      this.$emit('payment-view-add')
    },

    showPayment(event) {
      if(event === 'stripe') {
        this.currentPaymentComponent = Stripe;
      }
      if(event === 'invoice') {
        this.currentPaymentComponent = Invoice;
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
