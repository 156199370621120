<template>
  <div>
    <h4>{{ getMessage('addresses') }}</h4>
    <div v-if="loading">
      <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%; z-index: 1000;">
        <font-awesome-icon style="font-size: 40px; color: #11843D;" icon="fa-solid fa-spinner" spin/>
      </div>
    </div>
    <div v-else>
      <div v-for="item in user_addresses" :key="item.id" style="border-bottom: 1px solid #E5E5E5; margin-bottom: 10px; padding-bottom: 10px;">
        <div>
          {{ item.firstname }} {{ item.lastname }}<br/>
          {{ item.street }} {{ item.number }}<br/>
          {{ item.zip }} {{ item.city }}<br/>
          {{ item.countryName }}
        </div>
        <div class="mt-2">
          <!--<a href="#">Edit</a>-->
        </div>
        <div class="mt-2">
          <input type="checkbox" :id="'default-' + item.id" :checked="default_address === item.id" v-on:click="updateDefaultAddress(item.id)" :disabled="default_address === item.id" />
          <label :for="'default-' + item.id">&nbsp;Als Standardadresse verwenden</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: 'account-address-view',
  data () {
    return {
      loading: true,
      user_addresses: [],
      default_address: 0,
    }
  },
  computed: {
    ...mapGetters(['getMessage']),
  },
  mounted() {
    this.getAddresses()
  },
  methods: {
    getAddresses() {
      this.$store.dispatch("loadUserAddresses").then((result) => {
        this.user_addresses = result
        this.loading = false

        this.user_addresses.forEach(address => {
          if(address.default === 1){
            this.default_address = address.id
          }
        })
      }, async error => {
        console.error(error);
      })
    },
    updateDefaultAddress(address) {
      this.loading = true
      this.$store.dispatch("updateUserDefaultAddress", address).then(() => {
        this.default_address = address
        this.loading = false
      }, async error => {
        console.error(error);
      })
    }
  }
}
</script>

<style></style>