import { render, staticRenderFns } from "./CompanySignUp.vue?vue&type=template&id=ccbd3a64&scoped=true&"
import script from "./CompanySignUp.vue?vue&type=script&lang=js&"
export * from "./CompanySignUp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccbd3a64",
  null
  
)

export default component.exports