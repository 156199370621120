<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="dekra-bannerbg" style="height: 100%;">
          <h3 class="mt-0">Vielen Dank für Ihre Bestellung</h3>
          <div class="row">
            <div class="col-md-8">
              <p>Auflistung der nächsten Schritte:</p>
              <ul>
                <li>Sie erhalten in Kürze ein E-Mail</li>
                <li>Produktionszeit</li>
                <li>Versand Per Post was ist in dem Kuvert</li>
              </ul>
              <p>Hinweise zum Aufkleben/Befestigen</p>
              <p>Karte wo wird die Plakette aktuell Gebraucht</p>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.</p>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'checkout-error-view',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
