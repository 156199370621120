<template>
  <div>
    <MainNavigation />
    <div class="container" v-if="getAppLanguage === 'en'">
    </div>
    <div class="container" v-else-if="getAppLanguage === 'de'">
    </div>
    <Footer />
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import Footer from "@/components/Footer";
import {mapGetters} from "vuex";

export default {
  components: {
    MainNavigation, Footer
  },
  computed: {
    ...mapGetters(['getAppLanguage']),
  },
}
</script>

<style>

</style>