import Api from "./Api";
import Csrf from "@/services/Csrf";

export default {
    async create(cart) {
        let token = await Csrf.getCookie();
        return Api.post('/order/create', cart,
            {
                headers: {
                    // 'Content-Type': 'multipart/form-data', // by Sebastian (2023-03-10): Fix the "Missing boundary in multipart/form-data POST data" error at the address submission in the frontend, that prevents Laravel from sending 'Access-Control-Allow-Origin' HTTP headers, which leads to CORS policy blocked responce problems in the browser.
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async info(o) {
        let token = await Csrf.getCookie();
        return Api.get('/order/info/'+o.orderHash+'/'+o.orderId,
            {
                headers: {
                    // 'Content-Type': 'multipart/form-data', // by Sebastian (2023-03-10): 'multipart/form-data' content in a get request????
                    'X-CSRF-TOKEN': token.data
                }
            });
    }
};