<template>
  <footer>
    <div class="container">
      <div class="footer">
        <router-link target="_self" :to="{ name: 'Imprint', params: { locale: this.$i18n.locale }}">{{ getMessage('imprint') }}</router-link>
        <router-link target="_self" :to="{ name: 'Data Protection', params: { locale: this.$i18n.locale }}">{{ getMessage('privacy') }}</router-link>
        <router-link target="_self" :to="{ name: 'Terms and Conditions', params: { locale: this.$i18n.locale }}">{{ getMessage('tos') }}</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'main-footer',
  computed: {
    ...mapGetters(['getMessage']),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
