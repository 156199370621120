import Api from "./Api";
import Csrf from "@/services/Csrf";

export default {
    async loadUserPermission(page) {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/permission/' + page,
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async loadUserPermissions() {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/permissions',
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    }
};