<template>
  <div>
    <MainNavigation/>
    <BackgroundImagePanel :header="getMessage('headline1')">
      <div class="row">
        <div class="col-md-12">
          <router-view />


          </div>
        </div>


    </BackgroundImagePanel>

    <Footer/>
  </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation'
import Footer from '@/components/Footer'
import BackgroundImagePanel from "@/components/BackgroundImagePanel";
import {mapGetters} from "vuex";


export default {
  components: {
    MainNavigation, Footer, BackgroundImagePanel,
  },
  data() {
    return {
      currentShopPage: 'checkout-address-view',
    }
  },
  computed: {
    ...mapGetters(['getMessage']),
  },
  methods: {
    checkoutPayment() {

    },
    checkoutSuccess() {
      this.currentShopPage = 'checkout-success-view'
    }
  },
  mounted() {

  }
}
</script>

<style></style>