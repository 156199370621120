import Api from "./Api";
import Csrf from "@/services/Csrf";

export default {
    async loadCountries() {
        let token = await Csrf.getCookie();
        let url = '/countries/list?lang='+localStorage.getItem('VueAppLanguage');
        return Api.get(url,
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async setCurrentLanguage(language, loadTranslation) {
        let token = await Csrf.getCookie();
        let url = '/language/'+language;
        if(loadTranslation) {
            url += "?loadTranslation=true"
        }
        return Api.get(url,
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    }

};