<template>
  <div>
    <MainNavigation />
    <BackgroundImagePanel min-height="50vh">
      <div class="row">
        <div class="col-md-6">
          <div class="dekra-bannerbg text-black" style="height: 100%;">
            <h2 class="mt-0">{{ getMessage('login') }}</h2>
            <div class="errormsg" ref="errormsg" v-if="errorMessage">{{ errorMessage }}</div>
            <p>
              {{ getMessage('logintext') }}
            </p>
            <div class="mt-4">
              <input class="form-control form-control-lg" type="text" v-model="user.email" :placeholder="getMessage('email')">
              <input class="form-control form-control-lg mt-3" type="password" v-model="user.password" :placeholder="getMessage('password')">
            </div>
            <div class="d-flex justify-content-end mt-3">
              <button class="btn btn-lg btn-primary" type="submit" v-on:click="sendLogin">{{ getMessage('login') }}</button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="dekra-bannerbg text-black" style="height: 100%;">
            <h2 class="mt-0">{{ getMessage('register') }}</h2>
            <p>
              {{ getMessage('registertext') }}
            </p>
            <div class="mt-4">
              <div class="d-flex">
                <div class="d-check"></div>
                <div class="d-checkname">{{ getMessage('registercheckone') }}</div>
              </div>
              <div class="d-flex mt-2">
                <div class="d-check"></div>
                <div class="d-checkname">{{ getMessage('registerchecktwo') }}</div>
              </div>
              <div class="d-flex mt-2">
                <div class="d-check"></div>
                <div class="d-checkname">{{ getMessage('registercheckthree') }}</div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-3">
              <router-link target="_self" class="btn btn-lg btn-primary" :to="{ name: 'register', params: { locale: this.$i18n.locale }}">
                {{ getMessage('register') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImagePanel>
    <Footer />
  </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation'
import Footer from '@/components/Footer'
import BackgroundImagePanel from "@/components/BackgroundImagePanel";
import {mapGetters} from "vuex";

export default {
  components: {
    MainNavigation, Footer, BackgroundImagePanel
  },
  data() {
    return {
      loginError:false,
      loginErrorMessage:'',
      user: {
        email: '',
        password: ''
      },
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters(['getMessage']),
  },
  methods: {
    sendLogin() {
      let o =  this.user;

      this.$store.dispatch("setLogin", o).then(() => {
        this.errorMessage = false;
        window.location = '/'+this.$i18n.locale;
        //this.$router.push({ name: 'home', params: { locale: this.$i18n.locale } });
      }).catch((error) => {
        this.errorMessage = this.getMessage(error.response.data.info);
      })
    }
  },
  created() {
    console.log('Page Loaded');
  }
}
</script>

<style></style>