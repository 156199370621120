import axios from 'axios';

const baseURL =process.env.VUE_APP_API_URL;
const headers = {
    Authorization: 'Bearer '+localStorage.getItem('jwt')
};
let Api = axios.create({
    baseURL,
    headers,
    withCredentials: false
});

Api.defaults.withCredentials = true;

export default Api;

