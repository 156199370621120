import Api from "./Api";
import Csrf from "@/services/Csrf";

export default {
    async addCartItem(cartItem) {
        let token = await Csrf.getCookie();
        return Api.post('/basket/add', cartItem,
            {
                headers: {
                    //'Content-Type': 'multipart/form-data', // removed by Sebastian (2023-03-09): The header can not be send because PHP creates the error:
						// 		<br />
						// 		<b>Warning</b>:  Missing boundary in multipart/form-data POST data in <b>Unknown</b> on line <b>0</b><br />
						// Before, the first line of the index.php is interpreted. However, I could not see this output because the Browser does not show the content since the responce is blocked  by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource.
						// Laravel could not set the header because of the warning from PHP, ... doom loop
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async removeCartItem(vin) {
        return Api.delete('/basket/delete/'+vin);
    },
    async getCart() {
        let token = await Csrf.getCookie();
        return Api.get('/basket/list',
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async setAddresses(o) {
        let token = await Csrf.getCookie();
        o._token = token;
        return Api.post('/basket/addresses', o,
            {
                headers: {
                    //'Content-Type': 'multipart/form-data', // removed by Sebastian (2023-03-09): The header can not be send because PHP creates the error:
						// 		<br />
						// 		<b>Warning</b>:  Missing boundary in multipart/form-data POST data in <b>Unknown</b> on line <b>0</b><br />
						// Before, the first line of the index.php is interpreted. However, I could not see this output because the Browser does not show the content since the responce is blocked  by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource.
						// Laravel could not set the header because of the warning from PHP, ... doom loop
                    'X-CSRF-TOKEN': token
                }
        });
    },
    async checkVat(vat) {
        let token = await Csrf.getCookie();
        return Api.get('/order/checkvat/'+vat,
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async getBasketInfo() {
        let token = await Csrf.getCookie();
        return Api.get('/basket/info',
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    }
};