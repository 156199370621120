import Api from "./Api";
import Csrf from "@/services/Csrf";

export default {
    async loadUserOrders() {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/orders',
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async loadOpenUserOrders() {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/orders/open',
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async loadLastUserOrders() {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/orders/last',
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async saveUserProfile(o) {
        let token = await Csrf.getCookie();
        return Api.post('/account/user/profile/update', o,
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async saveUserPassword(o) {
        let token = await Csrf.getCookie();
        return Api.post('/account/user/password/update', o,
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async loadUserProfile() {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/profile',
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async loadUserAddresses() {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/addresses',
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async updateUserDefaultAddress(o) {
        let token = await Csrf.getCookie();
        return Api.post('/account/user/addresses/default', o,
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                }
            });
    },
    async setRegistration(o) {
        let token = await Csrf.getCookie();
        o._token = token;
        return Api.post('/account/user/register', o,
            {
                headers: {
                    'X-CSRF-TOKEN': token,
                }
            });
    },
    async setLogin(o) {
        let token = await Csrf.getCookie();
        o._token = token;
        return Api.post('/account/user/login', o,
            {
                headers: {
                    'X-CSRF-TOKEN': token
                }
            });
    },
    async setLogout() {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/logout',
            {
                headers: {
                    'X-CSRF-TOKEN': token
                }
            });
    },
    async check() {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/check',
            {
                headers: {
                    'X-CSRF-TOKEN': token
                }
            });
    },
    async downloadInvoice(options) {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/download/invoice/' + options.itemId,
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                },
                responseType: 'blob',
                'Content-Type': 'multipart/form-data'
            });
    },
    async downloadCertificate(options) {
        let token = await Csrf.getCookie();
        return Api.get('/account/user/download/certificate/' + options.itemId,
            {
                headers: {
                    'X-CSRF-TOKEN': token.data
                },
                responseType: 'blob',
                'Content-Type': 'multipart/form-data'
            });
    },
};