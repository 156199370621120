<template>
  <div>
    <MainNavigation />
    <BackgroundImagePanel min-height="50vh">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="dekra-bannerbg text-black" style="height: 100%;">
            <div class="text-center">
              <h2 class="mt-0">{{ getMessage('nearlyfinishedheader') }}</h2>
              <font-awesome-icon style="font-size: 50px; color: #0E853C;" icon="fa-solid fa-envelope" />
              <p class="mt-3">
                {{ getMessage('nearlyfinishedtext1') }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </BackgroundImagePanel>
    <Footer />
  </div>
</template>

<script>
import MainNavigation from '@/components/MainNavigation'
import Footer from '@/components/Footer'
import BackgroundImagePanel from "@/components/BackgroundImagePanel";
import {mapGetters} from "vuex";

export default {
  components: {
    MainNavigation, Footer, BackgroundImagePanel
  },
  data() {
    return {
      test: "testvalue",
      currentPage: "company-sign-up"
    }
  },
  computed: {
    ...mapGetters(['getMessage']),
  },
  methods: {

  },
}
</script>

<style></style>