<template>
  <div>
    <div class="mt-4">
      <div class="errormsg" ref="errormsg" v-if="errorMessage">{{ errorMessage }}</div>
      <h3>{{ getMessage('companydata') }}</h3>
      <div class="row">
        <div class="col-md-6">
          <input class="form-control form-control-lg" type="text" v-validate="'required'" name="Company" v-model="companydata.company" :placeholder="getMessage('companyname') + ' *'">
          <span> {{ errors.first('Company') }}</span>
        </div>
        <div class="col-md-6">
          <input class="form-control form-control-lg" type="text" name="UID" v-model="companydata.vat" :placeholder="getMessage('uid')">
          <span> {{ errors.first('UID') }}</span>
        </div>

      </div>
      <div class="mt-2">
        <input class="form-control form-control-lg" type="text" v-validate="''" name="MemberId" v-model="user.member_id" placeholder="Dekra Member ID">
        <span> {{ errors.first('MemberId') }}</span>
      </div>
      <div class="row mt-2">
        <div class="col-md-8">
          <input class="form-control form-control-lg" type="text" v-validate="'required'" name="Street" v-model="companydata.street" :placeholder="getMessage('street') + ' *'">
          <span> {{ errors.first('Street') }}</span>
        </div>
        <div class="col-md-4">
          <input class="form-control form-control-lg" type="text" v-validate="'required'" name="Number" v-model="companydata.number" :placeholder="getMessage('nr') + ' *'">
          <span> {{ errors.first('Number') }}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4">
          <input class="form-control form-control-lg" type="text" v-validate="'required'" name="ZIP" v-model="companydata.zip" :placeholder="getMessage('postalcode') + ' *'">
          <span> {{ errors.first('ZIP') }}</span>
        </div>
        <div class="col-md-8">
          <input class="form-control form-control-lg" type="text" v-validate="'required'" name="City" v-model="companydata.city" :placeholder="getMessage('city') + ' *'">
          <span> {{ errors.first('City') }}</span>
        </div>
      </div>
      <div class="mt-2">
        <select class="form-select form-select-lg" v-validate="'required'" name="Country" v-model="companydata.country">
          <option :value="null" disabled>{{ getMessage('country') }} *</option>
          <option
              v-for="(country, index) of countryItems"
              :key="index"
              :value="country.key"
          >{{ country.label }}
          </option>
        </select>
        <span> {{ errors.first('Country') }}</span>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <input class="form-control form-control-lg" type="text" v-validate="'required'" name="Phone" v-model="companydata.phone" :placeholder="getMessage('phone') + ' *'">
          <span> {{ errors.first('Phone') }}</span>
        </div>
        <div class="col-md-6">
          <input class="form-control form-control-lg" type="text" v-validate="''" name="Fax" v-model="companydata.fax" :placeholder="getMessage('fax')">
          <span> {{ errors.first('Fax') }}</span>
        </div>
      </div>
      <h3>{{ getMessage('contactperson') }}</h3>
      <div class="row mb-2">
        <div class="col-md-6">
          <select class="form-select form-select-lg" v-model="user.salutation" v-validate="'required'" name="Salutation" style="max-width: 320px;">
            <option :value="null" disabled>{{ getMessage('salutation') }} *</option>
            <option value="1">{{ getMessage('female') }}</option>
            <option value="2">{{ getMessage('male') }}</option>
          </select>
          <span> {{ errors.first('Salutation') }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <input class="form-control form-control-lg" type="text" v-validate="'required'" name="Firstname" v-model="user.firstname" :placeholder="getMessage('firstname') + ' *'">
          <span> {{ errors.first('Firstname') }}</span>
        </div>
        <div class="col-md-6">
          <input class="form-control form-control-lg" type="text" v-validate="'required'" name="Lastname" v-model="user.lastname" :placeholder="getMessage('lastname') + ' *'">
          <span> {{ errors.first('Lastname') }}</span>
        </div>
      </div>
      <div class="mt-2">
        <input class="form-control form-control-lg" type="text" v-validate="'required'" name="Phone" v-model="user.phone" :placeholder="getMessage('phone') + ' *'">
        <span> {{ errors.first('Phone') }}</span>
      </div>

      <h3>{{ getMessage('logindata') }}</h3>
      <input class="form-control form-control-lg" type="text" v-validate="'required|email'" name="Email" v-model="user.email" :placeholder="getMessage('youremail') + ' *'">
      <span> {{ errors.first('Email') }}</span>
      <p class="text-muted mt-4" style="font-size: 13px;">
        {{ getMessage('passwordtext') }}
      </p>
      <input class="form-control form-control-lg mt-2" type="password" v-validate="{ required: true, min: 8, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }" name="Password" ref="Password" v-model="user.password" :placeholder="getMessage('yourpassword') + ' *'">
      <span> {{ errors.first('Password') }}</span>
      <input class="form-control form-control-lg mt-2" type="password" v-validate="'required|confirmed:Password'" data-vv-as="Password" name="Passwordrepeat" v-model="user.passwordrepeat" :placeholder="getMessage('repeatpassword') + ' *'">
      <span> {{ errors.first('Passwordrepeat') }}</span>
      <div class="mt-3">
        <div class="form-check d-flex">
          <input class="form-check-input" type="radio" value="1" v-validate="'required|included:1'" name="Privacypolicy" v-model="privacypolicy" id="flexRadioDefault1">
          <label class="form-check-label" style="width: calc(100% - 32px);" for="flexRadioDefault1">
            <p class="mb-2 fw-bold">{{ getMessage('privacypolicy') }}</p>
            <p class="text-muted" style="font-size: 13px;">
              {{ getMessage('privacytext') }}
            </p>
          </label>
        </div>
        <span> {{ errors.first('Privacypolicy') }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-lg btn-filled btn-primary" v-on:click="sendRegistration" type="submit">{{ getMessage('register') }}</button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Validator} from "vee-validate";

export default {
  name: 'company-sign-up',
  computed: {
    ...mapGetters(['getMessage', 'countryItems', 'getAppLanguage']),
  },
  data() {
    return {
      currentEU: false,
      companydata: {
        company: "",
        vat: "",
        street: "",
        number: "",
        zip: "",
        city: "",
        phone: "",
        fax: "",
        country: null,
      },
      user: {
        salutation: null,
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        password: "",
        passwordrepeat: "",
        member_id: ""
      },
      privacypolicy: false,
      type: 'company',
      errorMessage: ''
    }
  },
  mounted(){
    this.validationErrors()
  },
  methods: {
    sendRegistration() {
      this.errorMessage = ''
      this.$validator.validate().then(valid => {
        if (valid) {
          this.checkVat().then((data) => {
            if(data){
              let o = {
                type: this.type,
                privacypolicy: this.privacypolicy,
                appLanguage: this.$store.getters.getAppLanguage,
                user: this.user,
                deliveryAddress: this.companydata,
              }
              this.$store.dispatch("setRegistration", o).then(() => {
                this.loading = false
                this.$router.push({name: 'register-confirmationmail', params: {locale: this.$i18n.locale}});
              }).catch((error) => {
                if(error.response.status === 422){
                  this.errorMessage = this.getMessage(error.response.data.message)
                }
                console.log(error)
              })
            }
            this.loading = false;
          });
        }
      })
    },
    checkVat(){
      this.loading = true;
      return new Promise((resolve) => {
        let self = this;
        this.isEU(this.companydata.country);
        if(this.currentEU){
          if(this.companydata.vat !== '' && this.companydata.vat !== null){
            this.$store.dispatch("checkVat", this.companydata.vat).then(function (response) {
              if(response.status === 200){
                resolve(true);
              } else {
                self.haserror = true;
                self.errorMessage = response.data.message;
                self.loading = false;
                resolve(false);
              }
            }).catch(function (error) {
              console.log (error)
              if(error.response.status === 400){
                self.haserror = true;
                self.errorMessage = error.response.data;
                self.loading = false;
                resolve(false);
              }
            });
          } else {
            this.loading = false;
            resolve(true);
          }
        } else {
          this.loading = false;
          resolve(true);
        }
      })
    },
    isEU(country){
      this.currentEU = false;
      if (!country) return false;
      let countries = this.countryItems;
      var filteredArray = countries.filter((item) => {
        return item.key === country
      });
      if (filteredArray[0].eu === 1) {
        this.currentEU = true;
      }
    },
    validationErrors(){
      var sprintf = (str, ...argv) => !argv.length ? str : sprintf(str = str.replace(sprintf.token||"%s", argv.shift()), ...argv);

      const dict = {
        custom: {
          Company: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('company'))
          },
          UID: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('uid'))
          },
          Street: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('street'))
          },
          Number: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('nr'))
          },
          ZIP: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('postalcode'))
          },
          City: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('city'))
          },
          Country: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('country'))
          },
          Fax: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('fax'))
          },
          Salutation: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('salutation'))
          },
          Firstname: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('firstname'))
          },
          Lastname: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('lastname'))
          },
          Phone: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('phone'))
          },
          DekraId: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('dekraid'))
          },
          Email: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('email')),
            email: () => sprintf(this.getMessage('emailinvalid'), this.getMessage('email'))
          },
          Password: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('password'))
          },
          Passwordrepeat: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('repeatpassword'))
          },
          Privacypolicy: {
            required: () => sprintf(this.getMessage('invalidfield'), this.getMessage('privacypolicy')),
            invalid: () => sprintf(this.getMessage('invalidfield'), this.getMessage('privacypolicy')),
            included: () => sprintf(this.getMessage('invalidfield'), this.getMessage('privacypolicy')),
            excluded: () => sprintf(this.getMessage('invalidfield'), this.getMessage('privacypolicy'))
          },
        }
      };

      Validator.localize('en', dict);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
