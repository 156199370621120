import LangRouter from "vue-lang-router";
import Vue from "vue";


import translations from "@/lang/translations";
import routes from "@/router/routes";


Vue.use(LangRouter, {
    defaultLanguage: process.env.VUE_APP_I18N_LOCALE,
    translations,
});

const router = new LangRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// router.beforeEach((to, from, next) => {
//     document.title = to.meta.title + ' - Dekra Shop'
//     next()
// });

export default router
