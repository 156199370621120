<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8">

          <h2 id="text0">{{ getMessage('infotitle01') }}</h2>
          <p>{{ getMessage('infotext01') }}</p>
          <h2 id="text1">{{ getMessage('infotitle02') }}</h2>
          <p>{{ getMessage('infotext02') }}</p>
          <h2 id="text2">{{ getMessage('infotitle03') }}</h2>
          <ul>
            <li style="color: #585858;">{{ getMessage('infotext03-01') }}</li>
            <li style="color: #585858;">{{ getMessage('infotext03-02') }}</li>
          </ul>
          <p>{{ getMessage('infotext03-03') }} <a href="https://ratgeber.wko.at/lkwfahrverbot/" target="_blank">https://ratgeber.wko.at/lkwfahrverbot/</a></p>
          <h2 id="text3">{{ getMessage('infotitle04') }}</h2>
          <p>{{ getMessage('infotext04') }}</p>
          <h2 id="text4">{{ getMessage('infotitle05') }}</h2>
          <p>{{ getMessage('infotext05') }}</p>
          <h2 id="text5">{{ getMessage('infotitle06') }}</h2>
          <p>{{ getMessage('infotext06') }}</p>
          <h2 id="text6">{{ getMessage('infotitle07') }}</h2>
          <p>{{ getMessage('infotext07') }}</p>

      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'bottom-info-text',
  computed: {
    ...mapGetters(['getAppLanguage', 'getMessage']),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
