<template>
  <div class="account-nav">
    <router-link class="navitem" :to="{ name: 'account-overview', params: { locale: this.$i18n.locale }}" v-if="permissions.indexOf('account') >= 0">
      <div class="icon"><font-awesome-icon icon="fa-solid fa-house" fixed-width /></div>
      <div class="name">{{ getMessage('overview') }}</div>
    </router-link>
    <router-link class="navitem" :to="{ name: 'account-orders', params: { locale: this.$i18n.locale }}" v-if="permissions.indexOf('orders') >= 0">
      <div class="icon"><font-awesome-icon icon="fa-solid fa-shopping-cart" fixed-width /></div>
      <div class="name">{{ getMessage('orders') }}</div>
    </router-link>
    <router-link class="navitem" :to="{ name: 'account-profile', params: { locale: this.$i18n.locale }}" v-if="permissions.indexOf('profile') >= 0">
      <div class="icon"><font-awesome-icon icon="fa-solid fa-user" fixed-width /></div>
      <div class="name">{{ getMessage('profile') }}</div>
    </router-link>
    <router-link class="navitem" :to="{ name: 'account-address', params: { locale: this.$i18n.locale }}" v-if="permissions.indexOf('address') >= 0">
      <div class="icon"><font-awesome-icon icon="fa-solid fa-address-book" fixed-width /></div>
      <div class="name">{{ getMessage('addresses') }}</div>
    </router-link>
    <div class="navitem"  @click="logout()">
      <div class="icon"><font-awesome-icon icon="fa-solid fa-power-off" fixed-width /></div>
      <div class="name">{{ getMessage('logout') }}</div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'account-navigation-view',
  computed: {
    ...mapGetters(['getMessage', 'loadUserPermissions']),
  },
  data () {
    return {
      permissions: [],
    }
  },
  mounted() {
    this.getPermissions()
  },
  methods: {
    getPermissions() {
      this.$store.dispatch("loadUserPermissions").then((result) => {
        this.permissions = result
      }, async error => {
        console.error(error);
      })
    },
    logout() {
      this.$store.dispatch("setLogout").then(() => {
        window.location = '/'+this.$i18n.locale;
      }, async error => {
        console.error(error);
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
