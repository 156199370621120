<template>
  <div>
    <MainNavigation />
    <div class="container" v-if="getAppLanguage !== 'de'">
      <h2>Imprint</h2>
      <b>DEKRA SE</b><br/><br/>

      Handwerkstrasse 15<br/>
      70565 Stuttgart<br/>
      Germany<br/><br/>

      phone: +49.711.78 61-0<br/>
      fax: +49.711.78 61-2240<br/>
      info@dekra.com<br/><br/>

      Chairmanship of the Supervisory Board:<br/>
      Stefan Kölbl<br/><br/>

      Authorised representatives of the Board of Management:<br/>
      Stan Zurkiewicz (Chairman)<br/>
      Ulrike Hetzel<br/>
      Wolfgang Linsenmaier<br/><br/>

      Registration Court: Local Court in Stuttgart<br/>
      Commercial Register HRB Number: 734316<br/>
      VAT Identification Number: DE 185 284 497<br/><br/>

      Responsible for content:<br/>
      DEKRA SE<br/>
      Corporate Marketing & Sales<br/>
      Mark Thomä<br/><br/>

      <h2>Disclaimer</h2>
      All rights reserved.<br/><br/>

      This website was created with great care. DEKRA makes the information available without warranty or guarantee of any kind, be it explicit or implied. Even if we assume that the information provided by us is accurate, it may include errors or inaccuracies.<br/><br/>

      DEKRA does not recognize non-DEKRA content in areas that can be reached via links from the DEKRA website (www.dekra.com or another DEKRA domain) as its own and assumes no responsibility for it. In particular, DEKRA is not subject to any obligation to review content. DEKRA explicitly distances itself from information that violates applicable law or common decency.<br/><br/>

      DEKRA’s website is protected by copyright, the right to intellectual property and all other relevant rights. Programmes, pictures, text and information contained therein are the intellectual property of DEKRA provided that there are no other intellectual property rights. Duplication, distribution, storage, sending, transfer and reproduction of the content without DEKRA’s written consent is expressly prohibited. We reserve the right to make technical modifications and errors.<br/><br/>

      Contact DEKRA, Stuttgart directly for more information.<br/><br/>

      Copyright © DEKRA
    </div>
    <div class="container" v-else-if="getAppLanguage === 'de'">
      <h2>Impressum</h2>
      <div class="textimage-holder right">
        <p>Herausgeber: DEKRA Austria GmbH. MAN-Strasse 1, A 2333 Leopoldsdorf bei Wien, Österreich</p><p></p><p>Medieninhaber: DEKRA SE, Handwerkstraße 15, D-70565 Stuttgart</p><p></p><p>Unternehmensgegenstand: Fahrzeugüberprüfung §57a, Tachometerüberprüfung gemäß §24 und §24a, Schadens- und Wertgutachten, Werkstatttests, Oldtimerbewertung, Gebrauchtwagenüberprüfung</p><p></p><p>Geschäftsführung: Mag. Helmut Geil</p><p></p><p>Kammerzugehörigkeit: Mitglied der Wirtschaftskammer Österreich, Fachgruppe Kraftfahrzeugtechniker</p><p></p><p>UID: ATU57314604</p><p></p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import Footer from "@/components/Footer";
import {mapGetters} from "vuex";

export default {
  components: {
    MainNavigation, Footer
  },
  computed: {
    ...mapGetters(['getAppLanguage']),
  },
}
</script>

<style>

</style>