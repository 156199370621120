<template>
  <div>
  <div class="row">
  <div v-for="item in cartItems" :key="item.vin">
    <CartItem :item="item" :isCheckout="isCheckout"/>
  </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-8">{{ getMessage('carttotal') }}</div>
    <div class="col-md-4 text-end"> {{ $n(cartInfo.sum, 'currency', 'de') }}</div>
  </div>
  <div class="row">
    <div class="col-md-8">{{ getMessage('deliverycost') }}</div>
    <div class="col-md-4 text-end"> {{ $n(cartInfo.deliveryFee, 'currency', 'de')  }}</div>
  </div>
  <hr v-if="cartInfo.tax && isCheckout">
    <div class="row" v-if="cartInfo.tax && isCheckout">
      <div class="col-md-8">+20% Ust</div>
      <div class="col-md-4 text-end "> {{ $n(cartInfo.total_tax, 'currency', 'de')   }}</div>
    </div>
  <hr>
  <div class="row">
    <div class="col-md-8"><b>{{ getMessage('carttotal') }}</b></div>
    <div class="col-md-4 text-end pprice"> {{ $n(cartInfo.total_with_tax, 'currency', 'de') }}</div>
  </div>
  </div>
</template>

<script>
import CartItem from "@/components/cart/CartItem";
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'cart-list',
  props: ['isCheckout'],
  components: {
    CartItem
  },
  computed: {
    ...mapGetters(['cartItems','cartInfo', 'getMessage']),
    ...mapActions(['getCartItems']),
    cartEmpty() {
      return this.cartInfo.count === 0;

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
