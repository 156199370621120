import Api from "@/services/Api";
import Cart from "@/services/Cart";
const state = {
    cartItems: [],
    info: {},
    vatValid: false,
    deliveryAddress: {},
    billingAddress: {},
    paymentTypes: [
        {
            name: 'Rechnung',
            value: 'invoice',
            disabled: false
        },
        {
            name: 'Online Payment',
            value: 'stripe',
            disabled: false
        }
    ]






}

const mutations = {
    SET_CART (state, payload) {
        state.cartItems = payload.data.list;
        state.info = payload.data.info;
        state.deliveryAddress = payload.data.deliveryAddress;
        state.billingAddress = payload.data.billingAddress;
    },
    SET_VAT_VALID (state) {
        state.vatValid = true;
    },
    SET_BASKET_INFO (state, payload) {
        state.info = payload.data.info;
    }

}

const actions = {
    getCartItems ({ commit }) {
        return new Promise((resolve, reject) => {
            Cart.getCart().then(( response) => {
                commit('SET_CART', response.data)
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    },
    addCartItem ({ commit }, cartItem) {
        return new Promise((resolve, reject) => {
            Cart.addCartItem(cartItem).then(( response) => {
                commit('SET_CART', response.data)
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    },
    removeCartItem ({ commit }, vin) {
        return new Promise((resolve, reject) => {
            Cart.removeCartItem(vin).then((response) => {
                commit('SET_CART', response.data)
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    },
    removeAllCartItems ({ commit }) {
        Api.delete('/api/cart/delete/all').then((response) => {
            commit('SET_CART', response.data)
        });
    },
    setAddresses ({ commit }, addresses) {
        return new Promise((resolve, reject) => {
            Cart.setAddresses(addresses).then(( response) => {
                commit('SET_CART', response.data)
                resolve(response);
            }, error => {
                reject(error);
            });
        });
    },
    checkVat ({ commit }, vat) {
        return new Promise((resolve) => {
            Cart.checkVat(vat).then((response) => {
                commit('SET_VAT_VALID');
                resolve(response);
            }, error => {
                resolve(error.response);
            });
        });
    },
    checkUserCart ({ commit }) {
        return new Promise((resolve) => {
            Cart.getBasketInfo().then((response) => {
                commit('SET_BASKET_INFO');
                resolve(response);
            }, error => {
                resolve(error.response);
            });
        });
    }
}

const getters = {
    cartItems: state => state.cartItems,
    cartTotal: state => state.info.sum,
    cartInfo: state => state.info,
    getCartCount: state => {
        return state.info.count ?? 0;
    },
    getPaymentTypes :  state => state.paymentTypes,
    getDeliveryAddress :  state => state.deliveryAddress,
    getBillingAddress :  state => state.billingAddress,
    hasDeliveryAddress: () => {
        return state.deliveryAddress !== null && Object.keys(state.deliveryAddress).length > 0
    },
    hasBillingAddress: () => {
        return state.deliveryAddress !== null && Object.keys(state.billingAddress).length > 0
    }
}


const cartModule = {
    state,
    mutations,
    actions,
    getters
}
export default cartModule;